import { useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ServiceSessions } from '../services/ServicesSession';
import { ContextValidToken } from '../App';


export const AuthGaurd = ({ children }) => {

    const dateFin = new Date(ServiceSessions.getDateExpire()).getTime();
    const navigate = useNavigate();
    const { tokenValide, hanldeVerifyToken } = useContext(ContextValidToken);
    
    useEffect(() => {
        // deconnexion automatique apres deux heure de temps
        const compteAReboursInterval = setInterval(() => {
            const dateActuelle = new Date().getTime();
            const difference = dateFin - dateActuelle;
            
            if (dateIsExist()) {
                if (difference <= 0) {
                    clearInterval(compteAReboursInterval);
                    ServiceSessions.logout();
                    return navigate('/');
                } 
            }else {
                ServiceSessions.logout();
                hanldeVerifyToken(true)
                return navigate('/');
            }
        }, 1000);

        return () => clearInterval(compteAReboursInterval);
    }, [dateFin, tokenValide]);
    
    const dateIsExist = () => {
        return Boolean(new Date(ServiceSessions.getDateExpire()).getTime() && ServiceSessions.getToken() && tokenValide);
    }
    
    return children
};
