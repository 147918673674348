import React, { Fragment, useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettingsCounties, ServicesSettingsLocality } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble, ValidateBtn } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';


export const AddLocality = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [localityPost, setLocalityPost] = useState({
        id_country: "",
        locality_name: "",
    });

    const handleOnChange = (e) => {
        setLocalityPost({
            ...localityPost,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getCountries();
    })

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    const createLocalities = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_country', localityPost.id_country)
        forms.append('locality_name', localityPost.locality_name)

        await ServicesSettingsLocality.post(forms).then((res) => {
            navigate('/admin/settings/locality');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data);
            toast.error(err.response.data);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/locality`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add locality</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createLocalities(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='id_country' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${localityPost.id_country === "" || localityPost.id_country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Locality</label>
                        <input onChange={handleOnChange} name='locality_name' type="text" className={`form-control`} id="currency" />
                        <small className={`${localityPost.locality_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/locality") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    )
}
