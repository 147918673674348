import { BASE_URL } from "../BASE_URL";
import { ServiceSessions } from "../ServicesSession";

export class ServicesFootSoldiersValidate {

    static url_footsoldiers = 'filtered-kyc/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_footsoldiers, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_footsoldiers}${id}/`, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}


export class ServicesFootSoldiersKYC {

    static url_kyc = 'kyc_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_kyc, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }


    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_kyc}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_kyc, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_kyc}${id}/`, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_kyc}${id}/`, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}