import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Traning } from '../views/Traning';
import { AddTraning } from '../views/forms/Add'; 
import { EditProperty } from '../views/forms/edit/property/forms/Edit'; 
import { LayoutsTranings } from '../views/forms/edit/LayoutsTranings';
import { Sections } from '../views/forms/edit/sections/Sections';
import { AddChapter } from '../views/forms/edit/sections/chapters/AddChapter';
import { EditChapter } from '../views/forms/edit/sections/chapters/EditChapter';
import { QuizSections } from '../views/forms/edit/sections/quiz/Quiz';
import { AddQuizSections } from '../views/forms/edit/sections/quiz/AddQuiz';
import { EidtQuizSections } from '../views/forms/edit/sections/quiz/EidtQuiz';
import { Exams } from '../views/forms/edit/exams/Exams';
import { AddQuizExams } from '../views/forms/edit/exams/quiz/AddQuiz';
import { QuizExams } from '../views/forms/edit/exams/quiz/Quiz';
import { EidtQuizExams } from '../views/forms/edit/exams/quiz/EidtQuiz';
import { ExamsEdit } from '../views/forms/edit/exams/Edit';
import { ExamsAdd } from '../views/forms/edit/exams/Add';

export const TraningControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Traning />} />
                <Route exact path='add' element={<AddTraning />} />

                {/* Edit traning */}
                <Route exact path=':id_training/' element={<LayoutsTranings />}>
                    <Route exact path='property/edit' element={<EditProperty />} />

                    {/* section */}
                    <Route exact path='section/'>
                        <Route exact path='' element={<Sections />} />
                        <Route exact path=':id_section/'>
                            <Route exact path='chapter/'>
                                <Route exact path='add' element={<AddChapter />} />
                                <Route exact path=':id_chapter/edit' element={<EditChapter />} />
                            </Route>
                            <Route exact path='quiz/'>
                                <Route exact path='' element={<QuizSections />} />
                                <Route exact path='add' element={<AddQuizSections />} />
                                <Route exact path=':id_quiz/edit' element={<EidtQuizSections />} />
                            </Route>
                        </Route>
                    </Route>

                    {/* Exams */}
                    <Route exact path='exam/'>
                        <Route exact path='' element={<Exams />} />
                        <Route exact path='add' element={<ExamsAdd />} />
                        <Route exact path=':id_exam/'>
                            <Route exact path='edit' element={<ExamsEdit />} />
                            <Route exact path='quiz/'>
                                <Route exact path='' element={<QuizExams />} />
                                <Route exact path='add' element={<AddQuizExams />} />
                                <Route exact path=':id_quiz/edit' element={<EidtQuizExams />} />
                            </Route>
                        </Route>
                    </Route>
                </Route>
            </Routes>
        </>
    );
};
