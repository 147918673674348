import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { MdEdit, MdDelete } from "react-icons/md";
import { toast } from 'react-toastify'; 
import { baseURLMedia } from '../../../../../services/BASE_URL';
import { SpinnerData } from '../../../../../components/SpinnerData'; 
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../../components/CustomModal';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';


export const Country = () => {

    const [countries, setCountries] = useState([]);
    const [ setTotalcountries] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        getCountries();
    }, []);


    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
            setTotalcountries(res.data.Total);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteCountries = async (id) => {
        setLoading(true);
        await ServicesSettingsCounties.delete(id).then((res) => {
            setShowModalDelete(false);
            getCountries();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }
    
    return (
        <>
            <section className='admin-settings-country'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/settings/country/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add country`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Number of Clients </th>
                                <th scope="col">Flag</th>
                                <th scope="col">Préfixe</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${countries.length !== 0 && 'd-none'}`}>
                                <td colSpan={5} className={`${!loadData && "custom-loading-data-td"} custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                </td>
                            </tr>
                            {
                               countries.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.country_name && item.country_name}</td>
                                            <td>{item.numbers_of_clients && item.numbers_of_clients}</td>
                                            <td>{item.flag && <img src={`${baseURLMedia}${item.flag}`} width={30} height={30} alt="rdc" />}</td>
                                            <td>{item.country_prefixe && item.country_prefixe}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/settings/country/edit/${item.id_country}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_country, item.country_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteCountries(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
