import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Targets } from '../views/Targets';
import { AddTargets } from '../views/forms/Add';
import { EditTargets } from '../views/forms/Edit';

export const TargetsControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Targets />} />
                <Route exact path='add' element={<AddTargets />} />
                <Route exact path='edit/:id_target' element={<EditTargets />} />
            </Routes>
        </>
    );
};
