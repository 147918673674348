import React, { Fragment, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesTranings } from '../../../../../../../../services/api/ServicesTrainings';
import { ServicesClients } from '../../../../../../../../services/api/ServicesClients';
import { ServicesSettingsCounties } from '../../../../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../../../../components/Button';

export const EditProperty = () => {

    const [client, setClient] = useState([]);
    const { id_training } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const [traningUpdate, setTraningUpdate] = useState({
        training_name: "",
        training_onBoarding: "",
        training_min_score: "",
        training_description: "",
        training_mode: "",
        training_statut: "",
        training_category: "",
        id_client: "",
        countrie_id: "",
    })


    useEffect(() => {
        document.title = "Training/properties/edit";
    });

    useEffect(() => {
        getCountries();
        getClients();
        getTraningsById();
    }, []);

    const handleOnChange = (e) => {
        setTraningUpdate({
            ...traningUpdate,
            [e.target.name]: e.target.value
        });
    }


    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    const getTraningsById = async () => {
        await ServicesTranings.getById(id_training).then((res) => {
            setTraningUpdate({
                training_name: res.data.data.training_name,
                training_onBoarding: res.data.data.training_onBoarding,
                training_min_score: res.data.data.training_min_score,
                training_description: res.data.data.training_description,
                training_mode: res.data.data.training_mode,
                training_statut: res.data.data.training_statut,
                training_category: res.data.data.training_category,
                id_client: res.data.data.id_client.id_client,
                countrie_id: res.data.data.countrie_id.id_country,
            });
        }).catch((err) => {
            return err
        });
    }


    const updateTranings = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_training', id_training)
        forms.append('training_name', traningUpdate.training_name)
        forms.append('training_min_score', traningUpdate.training_min_score)
        forms.append('training_min_score', traningUpdate.training_min_score)
        forms.append('training_description', traningUpdate.training_description)
        forms.append('training_mode', traningUpdate.training_mode)
        forms.append('training_statut', traningUpdate.training_statut)
        forms.append('training_category', traningUpdate.training_category)
        forms.append('id_client', Number(traningUpdate.id_client))
        forms.append('countrie_id', Number(traningUpdate.countrie_id))

        await ServicesTranings.put(id_training, forms).then((res) => {
            navigate('/admin/trainings');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="property-edit">
                <form onSubmit={(e) => updateTranings(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Name </label>
                        <input onChange={handleOnChange} value={traningUpdate.training_name} type="text" name='training_name' className='form-control' />
                        <small className={`${errors.training_name || traningUpdate.training_name === '' ? "errors-fields" : "d-none"}`}>{errors.training_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Description </label>
                        <textarea id="description" value={traningUpdate.training_description} onChange={handleOnChange} className={`form-control`} name="training_description" rows="3" ></textarea>
                        <small className={`${errors.training_description || traningUpdate.training_description === '' ? "errors-fields" : "d-none"}`}>{errors.training_description}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Mode  </label>
                        <select className={`form-select`} value={traningUpdate.training_mode} onChange={handleOnChange} name='training_mode' aria-label="Default select example">
                            <option>Selected mode</option>
                            <option value='0'>Inactif</option>
                            <option value='1'>Actif</option>
                        </select>
                        <small className={`${errors.training_mode && (traningUpdate.training_mode === "" || traningUpdate.training_mode === "Selected mode") ? "errors-fields" : "d-none"}`}>{errors.training_description}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Category </label>
                        <input onChange={handleOnChange} value={traningUpdate.training_category} type="text" name='training_category' className={`form-control ${errors.training_category && `erreur-field`}`} id="category" />
                        <small className={`${errors.training_category || traningUpdate.training_category ? "errors-fields" : "d-none"}`}>{errors.training_category}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} value={traningUpdate.id_client} onChange={handleOnChange} name='id_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${traningUpdate.id_client === '' || traningUpdate.id_client === "Selected client" ? "errors-fields" : "d-none"}`}>{errors.id_client}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} value={traningUpdate.countrie_id} onChange={handleOnChange} name='countrie_id' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${item.id_country}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${traningUpdate.countrie_id === "" || traningUpdate.countrie_id === "Selected country" ? "errors-fields" : "d-none"}`}>{errors.countrie_id}</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Onboarding  </label>
                        <select className={`form-select`} value={traningUpdate.training_onBoarding} onChange={handleOnChange} name='training_onBoarding' aria-label="Default select example">
                            <option>selected Onboarding</option>
                            <option value={true}>Yes</option>
                            <option value={false}>No</option>
                        </select>
                        <small className={`${traningUpdate.training_onBoarding === '' || traningUpdate.training_onBoarding === "Selected Onboarding" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Assessement Required (%) </label>
                        <input type="number" value={traningUpdate.training_min_score} onChange={handleOnChange} min={0} max={100} name="training_min_score" className={`form-control`} id="assesment" />
                        <small className={`${errors.training_min_score || traningUpdate.training_min_score === '' ? "errors-fields" : "d-none"}`}>{errors.training_min_score}</small>
                    </div>
                    <div className="">
                        <label htmlFor="" className="form-label">Status  : </label>
                        <select className={`form-select`} value={traningUpdate.training_statut} onChange={handleOnChange} name='training_statut'>
                            <option>Select Status</option>
                            <option value='0'>Inactif</option>
                            <option value='1'>Actif</option>
                        </select>
                        <small className={`${errors.training_statut || traningUpdate.training_statut === '' ? "errors-fields" : "d-none"}`}>{errors.training_statut}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate("/admin/trainings") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};