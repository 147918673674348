import React from 'react';
import { Outlet } from 'react-router-dom';
import { MenuNavBarSettings } from './Menu';


export const SettingsLayouts = () => {    
    return (
        <>
            <section id='admin-settings'>
                <MenuNavBarSettings/>
                <Outlet/>
            </section>
        </>
    );
};
