import React, { useEffect } from 'react';
import { Bars } from './Bars';
import { BsBarChartFill, BsFillPieChartFill } from "react-icons/bs";
import { GiNotebook } from "react-icons/gi";
import { FaUsers } from "react-icons/fa";
import { AiOutlineArrowUp } from "react-icons/ai";

export const Dashboard = () => {

    useEffect(() => {
        document.title = "Dashboard";
    },[]);

    return (
        <>
            <section id='home-dashboard'>
                <div className='container mt-2'>
                    <div className='row'>
                        <div className='col-12 my-1 col-sm-6 col-md-3'>
                            <div className='cardre d-flex flex-column'>
                                <div className='one d-flex justify-content-between align-items-center'>
                                    <div className='name-value'>
                                        <div className='name'>
                                            <h4>Sales Activity</h4>
                                        </div>
                                        <div className='value'>
                                            <strong>17,90</strong>
                                        </div>
                                    </div>
                                    <div className='icon'>
                                        <BsBarChartFill size={25} color='white' />
                                    </div>
                                </div>
                                <div className='two d-flex mt-2 justify-content-between'>
                                    <span><AiOutlineArrowUp /> 3,84 %</span>
                                    <span>Nomth to month</span>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 my-1 col-sm-6 col-md-3'>
                            <div className='cardre'>
                                <div className='one d-flex justify-content-between align-items-center'>
                                    <div className='name-value'>
                                        <div className='name'>
                                            <h4>Sales Activity</h4>
                                        </div>
                                        <div className='value'>
                                            <strong>17,90</strong>
                                        </div>
                                    </div>
                                    <div className='icon'>
                                        <BsFillPieChartFill size={25} color='white' />
                                    </div>
                                </div>
                                <div className='two'>
                                    <div className='two d-flex mt-2 justify-content-between'>
                                        <span><AiOutlineArrowUp /> 3,84 %</span>
                                        <span>Nomth to month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 my-1 col-sm-6 col-md-3'>
                            <div className='cardre'>
                                <div className='one d-flex justify-content-between align-items-center'>
                                    <div className='name-value'>
                                        <div className='name'>
                                            <h4>Sales Activity</h4>
                                        </div>
                                        <div className='value'>
                                            <strong>17,90</strong>
                                        </div>
                                    </div>
                                    <div className='icon'>
                                        <FaUsers size={25} color='white' />
                                    </div>
                                </div>
                                <div className='two'>
                                    <div className='two d-flex mt-2 justify-content-between'>
                                        <span><AiOutlineArrowUp /> 3,84 %</span>
                                        <span>Nomth to month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className='col-12 my-1 col-sm-6 col-md-3'>
                            <div className='cardre'>
                                <div className='one d-flex justify-content-between align-items-center'>
                                    <div className='name-value'>
                                        <div className='name'>
                                            <h4>Sales Activity</h4>
                                        </div>
                                        <div className='value'>
                                            <strong>17,90</strong>
                                        </div>
                                    </div>
                                    <div className='icon'>
                                        <GiNotebook size={25} color='white' />
                                    </div>
                                </div>
                                <div className='two'>
                                    <div className='two d-flex mt-2 justify-content-between'>
                                        <span><AiOutlineArrowUp /> 3,84 %</span>
                                        <span>Nomth to month</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-12 my-3 col-sm-6 col-md-4'>
                            <div className='bar_apezchart'>
                                <h3>Survey</h3>
                                <Bars />
                            </div>
                        </div>
                        <div className='col-12 my-3 col-sm-6 col-md-4'>
                            <div className='bar_apezchart'>
                                <h3>Sales</h3>
                                <Bars />
                            </div>
                        </div>
                        <div className='col-12 my-3 col-sm-6 col-md-4'>
                            <div className='bar_apezchart'>
                                <h3>Active Freelancers on 30 Days</h3>
                                <Bars />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};
