import React, { useEffect, useState } from 'react';
import { ServiceSessions } from '../../../../services/ServicesSession';
import { ServicesUsers } from '../../../../services/api/ServicesUsers';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KCustomButtonSimaple } from '../../../../components/Button';
import { SpinnerData } from '../../../../components/SpinnerData';


export const Accounts = () => {


    const [usersAccount, setUsersAccount] = useState([]);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [loading, setLoading] = useState(false);
    const [loadData, SetLoadData] = useState(true);
    const [modifyPassword, setModifyPassword] = useState({
        old_password: "",
        new_password: "",
    })

    useEffect(() => {
        document.title = "Account";
    });

    const handleOnChange = (e) => {
        setModifyPassword({
            ...modifyPassword,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getUsers();
    }, []);

    const getUsers = async () => {
        await ServicesUsers.get().then((res) => {
            setUsersAccount(res.data.User);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const CreatemodifyPassword = async (e) => {
        e.preventDefault();

        setLoading(true);

        const data = {
            old_password: modifyPassword.old_password,
            new_password: modifyPassword.new_password,
        }
 
        await ServicesUsers.modifyPassword(JSON.stringify(data)).then((res) => {
            navigate('/admin/accounts');
            toast.success(res.data.message)
            setModifyPassword({
                old_password: "",
                new_password: "",
            });
            setErrors("");
        }).catch((err) => {
            setErrors(err.response.data);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    let userId = ServiceSessions.getIdUsers();
    let userConnect = usersAccount.filter((user) => user.id === Number(userId))[0];
 

    return (
        <>
            <section id="admin-account">
                <div className="grid-custom-one-column">
                    <div className="grid-custom-one-column custom-display-info"> 
                        {
                            userConnect === undefined ? (
                                <>
                                    <div className='loading-for-display-data'><SpinnerData /></div>
                                </>
                            ) : (
                                <>
                                    {errors.error && errors.error}
                                    <div className='item-display'>
                                        <span className='label'>Date de création :</span>
                                        <span className='value'>{userConnect.date_joined && userConnect.date_joined}</span>
                                    </div>
                                    <div className='item-display'>
                                        <span className='label'>Profil :</span>
                                        <span className='value'>Administrateur des ventes</span>
                                    </div>
                                    <div className='item-display'>
                                        <span className='label'>Nom : </span>
                                        <span className='value'>{userConnect.nom && userConnect.nom}</span>
                                    </div>
                                    <div className='item-display'>
                                        <span className='label'>Prénoms :</span>
                                        <span className='value'>{userConnect.prenoms && userConnect.prenoms}</span>
                                    </div>
                                    <div className='item-display'>
                                        <span className='label'>Email :</span>
                                        <span className='value'>{userConnect.email && userConnect.email}</span>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div className="custom-display-change-password grid-custom-one-column">
                        {errors.error}
                        <form className='custom-form' onSubmit={(e) => CreatemodifyPassword(e)}>
                            <div className="item-form">
                                <label htmlFor="login" className="form-label">Ancien mot de passe </label>
                                <input type="password" onChange={handleOnChange} value={modifyPassword.old_password} name='old_password' className={`form-control ${(errors.old_password || errors.error) && `erreur-field`}`} />
                                <small className={`${errors.old_password && (errors.old_password[0] || modifyPassword.old_password === '') ? "errors-fields" : "d-none"}`}>{errors.old_password}</small>
                            </div>
                            <div className="item-form">
                                <label htmlFor="password2" className="form-label" >Nouveau mot de passe </label>
                                <input type="password" onChange={handleOnChange} value={modifyPassword.new_password} name='new_password' className={`form-control ${errors.new_password && `erreur-field`}`}  />
                                <small className={`${errors.new_password && (errors.new_password[0] || modifyPassword.new_password === '') ? "errors-fields" : "d-none"}`}>{errors.new_password}</small>
                            </div>
                            <br />
                            <div className='custom-btn-submit-position'>
                                <KCustomButtonSimaple loading={loading} texte={`Modifier`} type={`submit`} />
                            </div>
                        </form>
                    </div>
                </div>
            </section>
        </>
    );
};