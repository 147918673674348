import { Modal } from "react-bootstrap";
import { IoCloseCircleSharp } from 'react-icons/io5';

export const ModelTemplapleSimple = ({ show, hide, className, children, modalClassName }) => {

    return (
        <Modal
            show={show}
            size=""
            aria-labelledby="contained-modal-title-center"
            centered className={`modal-form-custom-all ${modalClassName}`}
            backdrop="static"
        >
            <div className='card-custom'>
                <IoCloseCircleSharp role='button' className='close-icon' onClick={hide} />
                <div className={`content content-${className}`}>
                    {children}
                </div>
            </div>
        </Modal>
    );
}