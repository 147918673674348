import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesProducts } from '../../../../services/api/ServicesProducts';
import { baseURLMedia } from '../../../../services/BASE_URL';
import { SpinnerData } from '../../../../components/SpinnerData';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../components/Button'; 
import { ModelTemplapleSimple } from '../../../../components/CustomModal';

export const Products = () => {

    const [products, setProducts] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        document.title = "Product";
    });

    useEffect(() => {
        getProduct();
    }, []);

    // Get all products
    const getProduct = async () => {
        await ServicesProducts.get().then((res) => {
            setProducts(res.data.Products);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteProduct = async (id) => {
        setLoading(true);
        await ServicesProducts.delete(id).then((res) => {
            setShowModalDelete(false);
            getProduct();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
            return err;
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>
            <section id='admin-products'>

                <div className='grid-custom-one-column'>
                    <h4 className='title'>Products list</h4>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/products/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add product `} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Product Name</th>
                                <th scope="col">Sale price</th>
                                <th scope="col">Icons</th>
                                <th scope="col">Training requireds</th>
                                <th scope="col">Rewards fees</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${products.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && products.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>

                            {
                                !loadData && products.map((items, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{items.product_name && items.product_name}</td>
                                            <td>{new Intl.NumberFormat('fr-FR').format(items.product_price ? items.product_price : 0)} FCFA</td>
                                            <td>
                                                <span>
                                                    <img src={items.product_picture && `${baseURLMedia}${items.product_picture}`} width={25} height={25} alt='reseau' />
                                                </span>
                                            </td>
                                            <td>{items.training_p && items.training_p.training_name}</td>
                                            <td>{new Intl.NumberFormat('fr-FR').format(items.product_commission ? items.product_commission : 0)} FCFA</td>
                                            <td className="td-action">
                                                <Link to={`/admin/products/edit/${items.id_product}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(items.id_product, items.product_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteProduct(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
