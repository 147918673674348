import React, { Fragment, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FiMap } from "react-icons/fi";
import { ServicesPOS } from '../../../../services/api/ServicesPOS';
import { FaDownload } from 'react-icons/fa';
import { baseURLDownloadsExcelFile, downloadsExcelFile_pos } from '../../../../services/BASE_URL';
import { SpinnerData } from './../../../../components/SpinnerData';
import { KCustomButtonSimaple } from '../../../../components/Button';

export const Pos = () => {

    const [POS, setPOS] = useState([]);
    const [totalcountries, setTotalcountries] = useState([]);
    const [loadData, SetLoadData] = useState(true);


    useEffect(() => {
        document.title = "POS";
    });


    useEffect(() => {
        getPOS();
    }, []);


    const getPOS = async () => {
        await ServicesPOS.get().then((res) => {
            setPOS(res.data.Pos);
            setTotalcountries(res.data.Total)
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    // const delete_pos = async (id) => {
    //     await ServicesPOS.delete(id).then((res) => {
    //         get_();
    //         toast.success(res.message);
    //     }).catch((err) => {
    //         toast.warning(err.response.data.detail);
    //     });
    // };

    return (
        <>
            <section id='admin-pos'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>POS List</h4>
                    <div className='custom-btn-header custom-end'>
                        <a href={baseURLDownloadsExcelFile + downloadsExcelFile_pos} download={`excel-file.xlsx`} className='link-btn-custom'>
                            <button type="button" className="btn-custom-green-icon me-2">
                                <FaDownload className='icon' />
                                <span>Excel file </span>
                            </button>
                        </a>
                        <Link to='/admin/pos/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Update  POS`} />
                        </Link>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Clients</th>
                                <th scope="col">Zones</th>
                                <th scope="col">POS Number</th>
                                <th scope="col">% of Active POS </th>
                                <th scope="col">Map View</th>
                                {/* <th scope="col">Actions</th> */}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${POS.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`${!loadData && "custom-loading-data-td"}  custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                </td>
                            </tr>
                            {
                                !loadData && POS.map((items, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>{items.pos_countrie && 'RDC'}</td>
                                            <td>{items.pos_client && 'ORANGE'}</td>
                                            <td>3</td>
                                            <td>{items.numb_pos && items.numb_pos}</td>
                                            <td>{items.pos_active && items.pos_active}</td>
                                            <td><FiMap /></td>
                                        </tr>
                                    </Fragment>
                                ))
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total : </td>
                                <td></td>
                                <td>11</td>
                                <td>91</td>
                                <td>168</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>
        </>
    );
};
