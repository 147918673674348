import axios from 'axios';

const baseURL = 'https://webapp.field360.pro/api/';
export const baseURLMedia = 'https://webapp.field360.pro/api';
export const baseURLVideo = 'https://webapp.field360.pro/api';

export const baseURLDownloadsExcelFile = 'https://webapp.field360.pro/api';
 

export const downloadsExcelFile_target = '/media/field360App/media/sheet.xlsx';
export const downloadsExcelFile_pos = '/media/field360App/media/sheet_pos.xlsx';
export const downloadsExcelFile_area = '/media/field360App/media/sheet_area.xlsx';

export const BASE_URL = axios.create({
  baseURL,
  // withCredentials: false,
});
