import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Accounts } from '../views/Accounts';

export const AccountsControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Accounts />} />
            </Routes>
        </>
    );
};
