import { BASE_URL } from "../BASE_URL";
import { ServiceSessions } from "../ServicesSession";

export class ServicesTranings {

    static url_traning = 'training_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_traning, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_traning}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_traning, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_traning}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_traning}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}

export class ServicesTraningSections {

    static url = 'section_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

} 

export class ServicesTraningChapters {

    static url = 'chapter_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                `create_chapter/`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `update_chapter/${id}/`, JSON.stringify(data), {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesTraningQuizAnswerSections {

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                'quiz_api_v1/', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `quiz_section_and_answers/${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                'create_quiz/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `update_quiz/${id}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `quiz_api_v1/${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }

}


export class ServicesTraningExams {

    static url = 'exam_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesTraningQuizAnswerExams {

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                'quizexam_api_v1/', {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `quiz_examen_and_answers/${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                'create_quiz_exam/', data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `update_quiz_exam/${id}/`, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `quizexam_api_v1/${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
 