import React, { Fragment, useEffect, useState } from 'react';
import { ServicesSettinPrivileges, ServicesSettingRoles } from '../../../../../services/api/ServicesSettings';
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../components/Button';
 import { IoChevronBackSharp } from 'react-icons/io5';

export const AddRole = () => {


    const [loading, setLoading] = useState(false);
    const [loadData, SetLoadData] = useState(true);
    const [privileges, setPrivileges] = useState([]);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [selectedRoles, setSelectedRoles] = useState([]);
    const [rolesPost, setRoles] = useState({
        privileges: [],
        role_name: ""
    });

    const [selectedItems, setSelectedItems] = useState([]);

    const handleRemoveItem = (itemToRemove) => {
        setSelectedItems(prevItems => prevItems.filter(item => item !== itemToRemove));
    };

    const handleSelectedItem = (event) => {
        const selectedItem = event.target.value;
        setSelectedItems(prevItems => {
            if (!prevItems.includes(selectedItem)) {
                return [...prevItems, selectedItem];
            }
            return prevItems;
        });
    };

    const handleOnChange = (e) => {
        setRoles({
            ...rolesPost,
            [e.target.name]: e.target.value
        });
    }

    // Get all privilege
    const getPrivileges = async () => {
        await ServicesSettinPrivileges.get().then((res) => {
            setPrivileges(res.data.Privilege);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    useEffect(() => {
        getPrivileges();
    }, []);


    const handleRoleChange = (event) => {
        const selectedOptions = Array.from(event.target.selectedOptions).map((option) => Number(option.value));
        setSelectedRoles(selectedOptions);
    };

    const createRoles = async (e) => {
        e.preventDefault();
        setLoading(true);

        var data = {
            privileges: selectedRoles,
            role_name: rolesPost.role_name,
        }

        await ServicesSettingRoles.post(JSON.stringify(data)).then((res) => {
            navigate('/admin/settings/role');
            toast.success("privilege create with successfull");
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/role`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add role</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createRoles(e)}>
                    <div className="mb-3">
                        <label htmlFor="role_name" className="form-label">Name  :</label>
                        <input type="text" onChange={handleOnChange} name='role_name' className={`form-control`} id="role_name" />
                        <small className={`${rolesPost.role_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Privilege :</label>
                        <div className="d-flex flex-column w-100">
                            <select
                                multiple
                                className="form-select"
                                name="privileges"
                                aria-label="Default select example"
                                onChange={handleRoleChange}
                                value={selectedRoles}>
                                {
                                    privileges.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option value={item.id}>{item.name}</option>
                                        </Fragment>
                                    )
                                }
                                <option value="8">read</option>
                                <option value="19">Update</option>
                                <option value="22">CountryRead</option>
                            </select>
                        </div>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/role") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
