
export const ExtractDate = ({ dateString }) => {
    const extractDate = (dateString) => {
        const dateObject = new Date(dateString);

        // Extraire la partie de la date
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1; // Les mois vont de 0 à 11, donc ajoutez 1
        const day = dateObject.getDate();

        // Créer une chaîne de date au format YYYY-MM-DD
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return formattedDate;
    };

    const extractedDate = extractDate(dateString);

    return (
        { extractedDate }

    );
};


export const getFileExtension = (filePath) => {
    if (typeof filePath !== 'string' || !filePath) {
        return '';
      }
      
      const parts = filePath.split('.');
      return parts.length > 1 ? parts.pop().toLowerCase() : ''; 
}


export const videoExtensions = [
    'mp4', 'avi', 'mov', 'mkv', 'flv',
    'wmv', 'webm', 'mpeg', 'mpg', '3gp',
    'ogv', 'ts', 'mts', 'm4v', 'rm',
    'rmvb', 'vob', 'asf'
];


export const imageExtensions = [
    'jpg', 'jpeg', 'png', 'gif', 'bmp',
    'tiff', 'tif', 'webp', 'svg', 'heif',
    'heic', 'ico', 'raw', 'cr2', 'nef',
    'arw', 'dng', 'orf', 'rw2', 'pef',
    'sr2', 'raf'
];


export const readFileAsBase64 = (file) => {
    return new Promise((resolve, reject) => {
        const reader = new FileReader();

        reader.onload = () => {
            resolve(reader.result);
        };

        reader.onerror = (error) => {
            reject(error);
        };

        reader.readAsDataURL(file);
    });
};


export const urlToBase64 = (url, callback) => {
    const xhr = new XMLHttpRequest();
    
    xhr.onload = () => {
        if (xhr.status === 200) {
            const reader = new FileReader();
            reader.onloadend = () => callback(null, reader.result);
            reader.onerror = () => callback(new Error('Error reading the file'));
            reader.readAsDataURL(xhr.response);
        } else {
            callback(new Error(`Failed to fetch URL: ${xhr.status}`));
        }
    };
    
    xhr.onerror = () => callback(new Error('Error fetching the URL'));
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
};

// Utilisation
// const handleConvertImage = () => {
//     const imageUrl = 'url'; // Remplacez par votre URL d'image
//     urlToBase64(imageUrl, (err, base64) => {
//       if (err) {
//         console.log("'Erreur lors de la conversion de l\'image'", err,);
//       } else {
//         console.log("Base64", base64);
//       }
//     });
// };

export const verifyBase64Media = (data) => {
    let convertData = data.split("/")[0]; 
    if (convertData === "data:video" || convertData === "data:image"){
        return true;
    }else{
        return false;
    }
}

export const verifyBase64MediaImage = (data) => {
    let convertData = data.split("/")[0];
    if (convertData === "data:image"){
        return true;
    }
}

export const verifyBase64MediaVideo = (data) => {
    let convertData = data.split("/")[0]; 
    if (convertData === "data:video"){
        return true;
    }
}