import React from 'react';



export const KCustomButtonSimaple = ({ loading, type, texte }) => {
    return (
        <>
            <button type={type} className={loading ? `btn-custom-desable` : `btn-custom-secondary`} disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                {texte}
            </button>
        </>
    );
};


export const KCustomButtonSimaple1 = ({ loading, type, texte }) => {
    return (
        <>
            <button type={type} className={loading ? `btn-custom-desable` : `btn-custom-primary`} disabled={loading}>
                {loading && <span className="spinner-border spinner-border-sm"></span>}
                {texte}
            </button>
        </>
    );
};

export const KCustomButtonDouble = ({ loading, typel, typer, clickl, clickr, textel, texter }) => {
    return (
        <>
            <div className='grid-custom-two-column grid-gap-custom-normal btn-project'>
                <button type={typel} onClick={clickl} className={`btn-custom-canceled`} disabled={loading}>
                     {textel}
                </button>
                <button type={typer} onClick={clickr} className={loading ? `btn-custom-desable` : `btn-custom-secondary`} disabled={loading}>
                    {loading && <span className="spinner-border spinner-border-sm"></span>}
                    {texter}
                </button>
            </div>
        </>
    );
};




export const ValidateBtn = ({ spninner }) => {
    return (
        <>
            <div className="mb-3 align-items-center d-sm-flex d-flex btn-form">
                <span className="ms-0 btn my-1 btn-cancel-form text-center btn-primary" onClick={() => window.history.back()}>Cancel</span>
                <button type="submit" className="me-0 btn my-1 btn-valide-form text-center btn-primary" disabled={spninner}>
                    {
                        spninner ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : 'Validate'
                    }
                </button>
            </div>
        </>
    );
};



export const ValidateBtnChangePassword = ({ spninner }) => {
    return (
        <>
            <div className="mb-3 align-items-center d-sm-flex d-flex btn-form">
                <button type="submit" className="mx-0 btn my-1 btn-valide-form text-center btn-primary" disabled={spninner}>
                    {
                        spninner ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : 'Modify'
                    }
                </button>
            </div>
        </>
    );
};


export const UpdateBtn = ({ spninner }) => {
    return (
        <>
            <div className="mb-3 align-items-center d-sm-flex d-flex btn-form">
                <span className="ms-0 btn my-1 btn-cancel-form text-center btn-primary" onClick={() => window.history.back()}>Cancel</span>
                <button type="submit" className="me-0 btn my-1 btn-valide-form text-center btn-primary" disabled={spninner}>
                    {
                        spninner ? <span className="spinner-border spinner-border-sm me-2" role="status" aria-hidden="true"></span> : 'Update'
                    }
                </button>
            </div>
        </>
    );
};
