import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { ServicesSettingsCounties, ServicesSettingsIndustries } from '../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const UpdateClient = () => {

    const { id_client } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [industries, setIndustries] = useState([]);
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [clientUpdate, setClientUpdate] = useState({
        client_logo: "",
        client_name: "",
        country_id: "",
        client_industry: "",
    });

    useEffect(() => {
        document.title = "Client/add";
    });

    const handleOnChange = (e) => {
        setClientUpdate({
            ...clientUpdate,
            [e.target.name]: e.target.value
        });
    }


    const handleFileOnChange = (e) => {
        setClientUpdate({
            ...clientUpdate,
            [e.target.name]: e.target.files[0]
        });
    }

    useEffect(() => {
        getCountries();
        getIndustries_();
        getClientsById();
    }, []);

    const getClientsById = async () => {
        await ServicesClients.getById(id_client).then((res) => {
            const { client_name, country_id, client_industry } = res.data.data;
            setClientUpdate({
                client_name,
                country_id,
                client_industry,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all industries
    const getIndustries_ = async () => {
        await ServicesSettingsIndustries.get().then((res) => {
            setIndustries(res.data.Industry);
        }).catch((err) => {
            return err
        })
    };

    const updateClients = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_client', id_client)
        forms.append('client_logo', clientUpdate.client_logo)
        forms.append('client_name', clientUpdate.client_name)
        forms.append('country_id', clientUpdate.country_id)
        forms.append('client_industry', clientUpdate.client_industry)

        await ServicesClients.put(id_client, forms).then((res) => {
            navigate('/admin/clients');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-client-update">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/clients`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Upade client</h4>
                    </div>
                </div>
                <form onSubmit={(e) => updateClients(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Client name </label>
                        <input onChange={handleOnChange} name='client_name' value={clientUpdate.client_name} type="text" className="form-control" placeholder="" />
                        <small className={`${errors.client_name && (errors.client_name[0] || clientUpdate.client_name === '') ? "errors-fields" : "d-none"}`}>{errors.client_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} value={clientUpdate.country_id} name='country_id' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.country_id && (clientUpdate.country_id === '' || clientUpdate.country_id === "Selected industries") ? "errors-fields" : "d-none"}`}>{errors.country_id}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Client logo </label>
                        <input onChange={handleFileOnChange} name='client_logo' type="file" className="form-control" placeholder="" />
                        <small className={`${errors.client_logo && (errors.client_logo[0] || clientUpdate.client_logo === '') ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Industries</label>
                        <select className={`form-select`} onChange={handleOnChange} value={clientUpdate.client_industry} name='client_industry'>
                            <option>Selected industries</option>
                            {
                                industries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_industry}>{item.industry_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.client_industry && (clientUpdate.client_industry === '' || clientUpdate.client_industry === "Selected industries") ? "errors-fields" : "d-none"}`}>{errors.client_industry}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate("/admin/clients") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
