import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesFootSoldiersKYC } from '../../../../../services/api/ServicesFieldSoldiers';
import { baseURLMedia } from '../../../../../services/BASE_URL';
import { toast } from 'react-toastify';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';


export const UpdateKYC = () => {

    const { id_footsolidiers } = useParams();
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [infoFootSoldier, setInfoFootSoldier] = useState({
        lastName: "",
        firstName: "",
        country: "",
        birthday: "",
        birth_place: "",
        locality: "",
        id_type: "",
        id_number: "",
        id_expiring_data: "",
        piece_verso: "",
        piece_recto: "",
        photo_selfie: "",
    });
    const [kycUpdate, setKycUpdate] = useState({
        kycAgentid: {
            account_validated: null
        },
        isPrenomOk: null,
        isNomOk: null,
        isCountryOk: null,
        isDateNaissanceOk: null,
        isLieuNaissanceOk: null,
        isLocalityOk: null,
        isTypePieceOk: null,
        isNumeroPieceOk: null,
        isDateExpirationOk: null,
        isPieceRectoOk: null,
        isPieceVersoOk: null,
        isPhotoSelfieOk: null,
    });

    const handleOnChange = (e) => {
         setKycUpdate({
            ...kycUpdate,
            [e.target.name]: e.target.checked
        });
    }

    useEffect(() => {
        getKYCById();
    }, []);

    const getKYCById = async () => {
        await ServicesFootSoldiersKYC.getById(id_footsolidiers).then((res) => {
            const data = res.data.data;
            const dataInfo = res.data.data.kycAgentid;
            setKycUpdate({
                kycAgentid: {
                    account_validated: data.kycAgentid.account_validated
                },
                isNomOk: data.isNomOk,
                isPrenomOk: data.isPrenomOk,
                isDateNaissanceOk: data.isDateNaissanceOk,
                isLieuNaissanceOk: data.isLieuNaissanceOk,
                isTypePieceOk: data.isTypePieceOk,
                isNumeroPieceOk: data.isNumeroPieceOk,
                isDateExpirationOk: data.isDateExpirationOk,
                isPhotoSelfieOk: data.isPhotoSelfieOk,
                isPieceRectoOk: data.isPieceRectoOk,
                isPieceVersoOk: data.isPieceVersoOk,
                isCountryOk: data.isCountryOk,
                isLocalityOk: data.isLocalityOk,
            });
            setInfoFootSoldier({
                lastName: dataInfo.prenoms,
                firstName: dataInfo.nom,
                country: dataInfo.country.country_name,
                birthday: dataInfo.date_naissance,
                birth_place: dataInfo.lieu_naissance,
                locality: dataInfo.localite.locality_name,
                id_type: dataInfo.type_piece.id_name,
                id_number: dataInfo.numero_piece,
                id_expiring_data: dataInfo.date_expiration,
                piece_verso: baseURLMedia + dataInfo.piece_verso,
                piece_recto: baseURLMedia + dataInfo.piece_recto,
                photo_selfie: baseURLMedia + dataInfo.photo_selfie,
            })
        }).catch((err) => {
            return err
        });
    }

    const extractDate = (dateString) => {
        const dateObject = new Date(dateString);
        const year = dateObject.getFullYear();
        const month = dateObject.getMonth() + 1;
        const day = dateObject.getDate();

        // Create a date string in YYYY-MM-DD format
        const formattedDate = `${year}-${month < 10 ? '0' : ''}${month}-${day < 10 ? '0' : ''}${day}`;

        return formattedDate;
    };

    const validationKYCClient = async (e) => {
        e.preventDefault();

        setLoading(true);
        const isAllFieldsValid = (
            kycUpdate.isNomOk &&
            kycUpdate.isPrenomOk &&
            kycUpdate.isDateNaissanceOk &&
            kycUpdate.isLieuNaissanceOk &&
            kycUpdate.isTypePieceOk &&
            kycUpdate.isNumeroPieceOk &&
            kycUpdate.isDateExpirationOk &&
            kycUpdate.isPhotoSelfieOk &&
            kycUpdate.isPieceRectoOk &&
            kycUpdate.isPieceVersoOk &&
            kycUpdate.isCountryOk &&
            kycUpdate.isLocalityOk
        );
        const data = {
            kycAgentid: {
                account_validated: isAllFieldsValid,
            },
            isNomOk: kycUpdate.isNomOk,
            isPrenomOk: kycUpdate.isPrenomOk,
            isDateNaissanceOk: kycUpdate.isDateNaissanceOk,
            isLieuNaissanceOk: kycUpdate.isLieuNaissanceOk,
            isTypePieceOk: kycUpdate.isTypePieceOk,
            isNumeroPieceOk: kycUpdate.isNumeroPieceOk,
            isDateExpirationOk: kycUpdate.isDateExpirationOk,
            isPhotoSelfieOk: kycUpdate.isPhotoSelfieOk,
            isPieceRectoOk: kycUpdate.isPieceRectoOk,
            isPieceVersoOk: kycUpdate.isPieceVersoOk,
            isCountryOk: kycUpdate.isCountryOk,
            isLocalityOk: kycUpdate.isLocalityOk
        }

        await ServicesFootSoldiersKYC.put(Number(id_footsolidiers), JSON.stringify(data)).then((res) => {
            navigate('/admin/foot-soldiers/pending');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id='admin-fs-validation'>
                <div className='header-custom'>
                    <Link to={`/admin/foot-soldiers/pending`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <h5 className='title'>Checker pour approuver chaque information</h5>
                </div>
                <form className='custom-form' onSubmit={(e) => validationKYCClient(e)}>
                    <div className='grid-custom-four-column'>
                        <div className='grid-custom-one-column grid-gap-custom-small'>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>LastName :</span>
                                    <span>{infoFootSoldier.lastName}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isPrenomOk} checked={kycUpdate.isPrenomOk} type="checkbox" role="switch" name="isPrenomOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>Name :</span>
                                    <span>{infoFootSoldier.firstName}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isNomOk} checked={kycUpdate.isNomOk} type="checkbox" role="switch" name="isNomOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>Country :</span>
                                    <span>{infoFootSoldier.country}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isCountryOk} checked={kycUpdate.isCountryOk} type="checkbox" role="switch" name="isCountryOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>Birthdate :</span>
                                    <span>{infoFootSoldier.birthday}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isDateNaissanceOk} checked={kycUpdate.isDateNaissanceOk} type="checkbox" role="switch" name="isDateNaissanceOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>Birth place :</span>
                                    <span>{extractDate(infoFootSoldier.birth_place)}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isLieuNaissanceOk} checked={kycUpdate.isLieuNaissanceOk} type="checkbox" role="switch" name="isLieuNaissanceOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>Locality :</span>
                                    <span>{infoFootSoldier.locality}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isLocalityOk} checked={kycUpdate.isLocalityOk} type="checkbox" role="switch" name="isLocalityOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>ID Type" :</span>
                                    <span>{infoFootSoldier.id_type}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isTypePieceOk} checked={kycUpdate.isTypePieceOk} type="checkbox" role="switch" name="isTypePieceOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>ID Number :</span>
                                    <span>{infoFootSoldier.id_number}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isNumeroPieceOk} checked={kycUpdate.isNumeroPieceOk} type="checkbox" role="switch" name="isNumeroPieceOk" onChange={handleOnChange} />
                                </div>
                            </div>
                            <div className='item-content'>
                                <div className='item-key-value'>
                                    <span className=''>ID Expiring date :</span>
                                    <span>{extractDate(infoFootSoldier.id_expiring_data)}</span>
                                </div>
                                <div className="form-check form-switch">
                                    <input className="form-check-input" value={kycUpdate.isDateExpirationOk} checked={kycUpdate.isDateExpirationOk} type="checkbox" role="switch" name="isDateExpirationOk" onChange={handleOnChange} />
                                </div>
                            </div>
                        </div>
                        <div className='kmedia'>
                            <h5 className='title'>ID Recto</h5>
                            <img src={infoFootSoldier.piece_recto} alt='photo_ de la piece recto' />
                            <div className="form-check form-switch">
                                <input className="form-check-input" value={kycUpdate.isPieceRectoOk} checked={kycUpdate.isPieceRectoOk} name="isPieceRectoOk" type="checkbox" role="switch" onChange={handleOnChange} />
                            </div>
                        </div>
                        <div className='kmedia'>
                            <h5 className='title'>Verso</h5>
                            <img src={infoFootSoldier.piece_verso} alt='photo_ de la piece verso' />
                            <div className="form-check form-switch">
                                <input className="form-check-input" value={kycUpdate.isPieceVersoOk} checked={kycUpdate.isPieceVersoOk} name="isPieceVersoOk" type="checkbox" role="switch" onChange={handleOnChange} />
                            </div>
                        </div>
                        <div className='kmedia'>
                            <h5 className='title'>Selfie</h5>
                            <img src={infoFootSoldier.photo_selfie} alt='photo_ selfi' />
                            <div className="form-check form-switch">
                                <input className="form-check-input" value={kycUpdate.isPhotoSelfieOk} checked={kycUpdate.isPhotoSelfieOk} name="isPhotoSelfieOk" type="checkbox" role="switch" onChange={handleOnChange} />
                            </div>
                        </div>
                    </div>
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/clients") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
