import React, { Fragment, useEffect, useState } from 'react';
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from 'react-router-dom';
import { AiOutlineShoppingCart } from "react-icons/ai";
import { toast } from 'react-toastify';
import { ServicesSettingsIndustries } from '../../../../../services/api/ServicesSettings';
import { SpinnerData } from '../../../../../components/SpinnerData'; 
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../../components/CustomModal';


export const Industy = () => {

    const [industries, setIndustries] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        getIndustries();
    }, []);


    // Get all industries
    const getIndustries = async () => {
        await ServicesSettingsIndustries.get().then((res) => {
            setIndustries(res.data.Industry);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })

    };


    const deleteIndustries = async (id) => {
        setLoading(true);
        await ServicesSettingsIndustries.delete(id).then((res) => {
            setShowModalDelete(false);
            getIndustries();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    let nbre = 0;
    industries.map((i) => (
        nbre += i.industry_status
    ))


    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>

            <section className='admin-settings-industry'>
                <div className='grid-custom-one-column'>
                    {/* <h4 className='title'>Industry types  </h4> */}
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/settings/industry/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add industry type`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Logo</th>
                                <th scope="col">Industry of Name </th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${industries.length !== 0 && 'd-none'}`}>
                                <td colSpan={3} className={`${!loadData && "custom-loading-data-td"}  custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                </td>
                            </tr>
                            {
                                !loadData && industries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr>
                                            <td>
                                                <AiOutlineShoppingCart size={30} />
                                            </td>
                                            <td>{item.industry_name && item.industry_name}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/settings/industry/edit/${item.id_industry}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_industry, item.industry_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                        <tfoot>
                            <tr>
                                <td>Total : </td>
                                <td>{!loadData && nbre}</td>
                                <td></td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteIndustries(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
