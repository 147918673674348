import React, { createContext, useState } from 'react';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { RoutesAdmin, RoutesAuthentication } from './modules/routers/Routes';
import { AuthGaurd } from './helpers/AuthGaurd';
import { Login } from './modules/auth/views/Login';
import { ToastMessageTopRight } from './components/ToastMessage';



export const ContextValidToken = createContext()

const App = () => {

    const [tokenValide, setTokenValide] = useState(true);

    const hanldeVerifyToken = (token) => setTokenValide(token)

    return (
        <>
            <BrowserRouter>
                <ContextValidToken.Provider value={{ tokenValide, hanldeVerifyToken }}>
                    <Routes>
                        <Route exact index element={<Login />} />
                        <Route exact path='/*' element={<RoutesAuthentication />} />
                        <Route exact path='admin/*' element={
                            <AuthGaurd>
                                <RoutesAdmin />
                            </AuthGaurd>
                        } />
                    </Routes>
                </ContextValidToken.Provider>
            </BrowserRouter>
            
            <ToastMessageTopRight />
        </>
    );
};

export default App;