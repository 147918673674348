import React, { Fragment, useEffect, useState } from 'react'
import { toast } from 'react-toastify';
import { Link, useNavigate } from 'react-router-dom';
import { ServicesProducts } from '../../../../services/api/ServicesProducts';
import { ServicesSettingsCounties } from '../../../../services/api/ServicesSettings';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const CreateProducts = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [countries, setCountries] = useState([]);
    const [errors, setErrors] = useState([]);
    const navigate = useNavigate();
    const [productsPost, setProductsPost] = useState({
        product_picture: "",
        product_name: "",
        product_price: "",
        product_commission: "",
        country: "",
        client: "",
        training_p: "",
    })

    const handleFileOnChange = (e) => {
        setProductsPost({
            ...productsPost,
            [e.target.name]: e.target.files[0]
        });
    }

    const handleOnChange = (e) => {
        setProductsPost({
            ...productsPost,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
    }, []);

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    const createProduct = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('product_picture', productsPost.product_picture)
        forms.append('product_name', productsPost.product_name)
        forms.append('product_price', productsPost.product_price)
        forms.append('product_commission', productsPost.product_commission)
        forms.append('country', productsPost.country)
        forms.append('client', productsPost.client)
        forms.append('training_p', productsPost.training_p)

        await ServicesProducts.post(forms).then((res) => {
            navigate('/admin/products');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-products-create">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/products`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add product</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createProduct(e)}>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Product Name</label>
                        <input onChange={handleOnChange} name='product_name' type="text" className="form-control" placeholder="" />
                        <small className={`${errors.product_name && (errors.product_name[0] || productsPost.product_name === '') ? "errors-fields" : "d-none"}`}>{errors.product_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='country' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${productsPost.country === '' || productsPost.country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${productsPost.client === '' || productsPost.client === "Selected client" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Picture </label>
                        <input onChange={handleFileOnChange} name='product_picture' type="file" className="form-control" placeholder="" />
                        <small className={`${errors.product_picture || productsPost.product_picture === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Price <small>FCFA</small> </label>
                        <input onChange={handleOnChange} name='product_price' type="text" className="form-control" placeholder="" />
                        <small className={`${errors.product_price && (errors.product_price[0] || productsPost.product_price === '') ? "errors-fields" : "d-none"}`}>{errors.product_price}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Commissions <small>FCFA</small> </label>
                        <input onChange={handleOnChange} name='product_commission' type="number" className="form-control" placeholder="" />
                        <small className={`${errors.product_commission && (errors.product_commission[0] || productsPost.product_commission === '') ? "errors-fields" : "d-none"}`}>{errors.product_commission}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/products") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    )
}
