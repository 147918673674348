import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';
import { ServicesTargets } from '../../../../../services/api/ServicesTargets';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditTargets = () => {

    const [client, setClient] = useState([]);
    const { id_target } = useParams();
    const [loading, setLoading] = useState(false);
    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [targetUpdate, setTargetUpdate] = useState({
        id_target: "",
        target_zone: "",
        target_month: "",
        target_moderm: "",
        target_routeurs: "",
        target_airtelmoney: "",
        target_countrie: "",
        target_client: ""
    })

    useEffect(() => {
        document.title = "Target/edit";
    });

    useEffect(() => {
        getCountries();
        getClients();
        getTargetsById();
    }, []);


    const handleOnChange = (e) => {
        setTargetUpdate({
            ...targetUpdate,
            [e.target.name]: e.target.value
        });
    }

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    const getTargetsById = async () => {
        await ServicesTargets.getById(id_target).then((res) => {
            let data = res.data.data;
            setTargetUpdate({
                target_zone: data.target_zone,
                target_month: data.target_month,
                target_moderm: data.target_moderm,
                target_routeurs: data.target_routeurs,
                target_airtelmoney: data.target_airtelmoney,
                target_countrie: data.target_countrie && data.target_countrie.id_country,
                target_client: data.target_client && data.target_client.id_client,
            });
        }).catch((err) => {
            return err
        });
    }

    const updateTargets = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_target', id_target)
        forms.append('target_zone', targetUpdate.target_zone)
        forms.append('target_month', targetUpdate.target_month)
        forms.append('target_moderm', targetUpdate.target_moderm)
        forms.append('target_routeurs', targetUpdate.target_routeurs)
        forms.append('target_airtelmoney', targetUpdate.target_airtelmoney)
        forms.append('target_countrie', targetUpdate.target_countrie)
        forms.append('target_client', targetUpdate.target_client)

        await ServicesTargets.put(id_target, forms).then((res) => {
            navigate('/admin/targets');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-targets-update">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/targets`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update targets</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateTargets(e)}>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Zone </label>
                        <input type="text" className="form-control" name='target_zone' value={targetUpdate.target_zone} onChange={handleOnChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Month </label>
                        <input type="month" className="form-control" name='target_month' onChange={handleOnChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Modern </label>
                        <input type="number" className="form-control" name='target_moderm' value={targetUpdate.target_moderm} onChange={handleOnChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Routeurs </label>
                        <input type="number" className="form-control" name='target_routeurs' value={targetUpdate.target_routeurs} onChange={handleOnChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> AIRTEL money </label>
                        <input type="number" className="form-control" name='target_airtelmoney' value={targetUpdate.target_airtelmoney} onChange={handleOnChange} />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} value={targetUpdate.target_client} name='target_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${targetUpdate.target_client === '' || targetUpdate.target_client === "Selected client" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='target_countrie' value={targetUpdate.target_countrie} >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${targetUpdate.target_countrie === "" || targetUpdate.target_countrie === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Validate`}
                        clickl={() => { navigate("/admin/targets") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
