import React, { useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import { ContextSettings } from '../views/context/ContextSettings';
import { SettingsLayouts } from '../views/Settings';
import { Privileges } from '../views/Privileges/Privileges';
import { AddPrivileges } from '../views/Privileges/Add';
import { EditPrivileges } from '../views/Privileges/Edit';
import { LevelOfStudies } from '../views/Level of studies/LevelOfStudies';
import { AddLevelOfStudies } from '../views/Level of studies/Add';
import { EditLevelOfStudies } from '../views/Level of studies/Edit';
import { Industy } from '../views/Industry/Industy';
import { AddIndusty } from '../views/Industry/Add';
import { EditIndusty } from '../views/Industry/Edit';
import { IDType } from '../views/ID Type/IDType';
import { AddIDType } from '../views/ID Type/Add';
import { EditIDType } from '../views/ID Type/Edit';
import { Country } from '../views/Country/Country';
import { AddCountry } from '../views/Country/Add';
import { EditCountry } from '../views/Country/Edit';
import { AddRole } from '../views/role/Add';
import { EditRole } from '../views/role/Edit';
import { Role } from '../views/role/Role';
import { Locality } from '../views/locality/Locality';
import { AddLocality } from '../views/locality/AddLocality';
import { EditLocality } from '../views/locality/EditLocality';

export const SettingsControllers = () => {

    const [successMessage, SetSuccessMessage] = useState(null)

    return (
        <>
            <ContextSettings.Provider value={{ message: { successMessage, SetSuccessMessage } }}>
                <Routes>
                    <Route exact element={<SettingsLayouts />}> 
                        <Route exact path='country/'>
                            <Route exact path='' element={<Country />} />
                            <Route exact path='add' element={<AddCountry />} />
                            <Route exact path='edit/:id_country' element={<EditCountry />} />
                        </Route>

                        <Route exact path='level-of-study/'>
                            <Route exact path='' element={<LevelOfStudies />} />
                            <Route exact path='add' element={<AddLevelOfStudies />} />
                            <Route exact path='edit/:id_level_of_study' element={<EditLevelOfStudies />} />
                        </Route>

                        <Route exact path='id-type/'>
                            <Route exact path='' element={<IDType />} />
                            <Route exact path='add' element={<AddIDType />} />
                            <Route exact path='edit/:id_id_type' element={<EditIDType />} />
                        </Route>

                        <Route exact path='privilege/'>
                            <Route exact path='' element={<Privileges />} />
                            <Route exact path='add' element={<AddPrivileges />} />
                            <Route exact path='edit/:id_privilege' element={<EditPrivileges />} />
                        </Route>

                        <Route exact path='role/'>
                            <Route exact path='' element={<Role />} />
                            <Route exact path='add' element={<AddRole />} />
                            <Route exact path='edit/:id_role' element={<EditRole />} />
                        </Route>

                        <Route exact path='industry/'>
                            <Route exact path='' element={<Industy />} />
                            <Route exact path='add' element={<AddIndusty />} />
                            <Route exact path='edit/:id_industry' element={<EditIndusty />} />
                        </Route>

                        <Route exact path='locality/'>
                            <Route exact path='' element={<Locality />} />
                            <Route exact path='add' element={<AddLocality />} />
                            <Route exact path=':id_locality/edit' element={<EditLocality />} />
                        </Route>
                    </Route>

                    <Route exact>
                    </Route>
                </Routes>
            </ContextSettings.Provider>
        </>
    );
};
