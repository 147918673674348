import React, { Fragment, useEffect, useState } from 'react';
import { ServicesArea } from '../../../../services/api/ServicesAera';
import { Link } from 'react-router-dom';
import { FaDownload } from 'react-icons/fa';
import { baseURLDownloadsExcelFile, downloadsExcelFile_area } from '../../../../services/BASE_URL';
import { SpinnerData } from '../../../../components/SpinnerData';
import { KCustomButtonSimaple } from '../../../../components/Button';



export const Area = () => {

    const [area, setarea] = useState([]); 
    const [loadData, SetLoadData] = useState(true);

    useEffect(() => {
        document.title = "Area";
    });

    useEffect(() => {
        getArea();
    }, []);

    const getArea = async () => {
        await ServicesArea.get().then((res) => {
            setarea(res.data.Area); 
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    }; 
    

    return (
        <>
            <section id='admin-area'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Liste des zones</h4>
                    <div className='custom-btn-header custom-end'>
                        <a href={baseURLDownloadsExcelFile + downloadsExcelFile_area} download={`excel-file.xlsx`} className='link-btn-custom'>
                            <button type="button" className="btn-custom-green-icon me-2">
                                <FaDownload className='icon' />
                                <span>Excel file </span>
                            </button>
                        </a>
                        <Link to='/admin/area/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Update  Area`} />
                        </Link>
                    </div>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Zones</th> 
                                <th scope="col">Client</th>
                                <th scope="col">Country</th>
                                <th scope="col">City</th>
                                <th scope="col">Number of territoiries</th>
                                <th scope="col">Site name</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${area.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && area.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && area.map((item, index) => (
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.area_zone_name && item.area_zone_name}</td>  
                                            <td>{item.area_client && item.area_client.client_name}</td> 
                                            <td>{item.area_countrie && item.area_countrie.country_name}</td> 
                                            <td>{item.area_city_name && item.area_city_name.city_name}</td> 
                                            <td>{item.area_territory_name && item.area_territory_name.area_territory_name}</td> 
                                            <td>{item.area_site_name && item.area_site_name}</td> 
                                        </tr>
                                    </Fragment>
                                ))
                            }
                            <tfoot>
                                <tr>
                                    <td>Total : </td>
                                    <td>168</td>
                                    <td></td>
                                </tr>
                            </tfoot>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};