import React, { Fragment, useEffect, useState } from "react";
import { MdEdit, MdDelete } from "react-icons/md";
import { Link } from "react-router-dom";
import { toast } from "react-toastify";
import { ServicesClients } from "../../../../services/api/ServicesClients";
import { baseURLMedia } from "../../../../services/BASE_URL";
import { SpinnerData } from "../../../../components/SpinnerData"; 
import { KCustomButtonDouble, KCustomButtonSimaple } from "../../../../components/Button";
import { ModelTemplapleSimple } from './../../../../components/CustomModal';


export const Clients = () => {

    const [client, setClient] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        document.title = "Client";
    });

    useEffect(() => {
        getClients();
    }, []);

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteClients = async (id) => {
        setLoading(true);
        await ServicesClients.delete(id).then((res) => {
            setShowModalDelete(false);
            getClients();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true); 
    }

    return (
        <>
            <section id="admin-client">
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Clients list</h4>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/clients/add' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add client`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Client name</th>
                                <th scope="col">Industries</th>
                                <th scope="col">Country</th>
                                <th scope="col">Status</th>
                                <th scope="col">Logo</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${client.length !== 0 && 'd-none'}`}>
                                <td colSpan={6} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && client.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.client_name && item.client_name}</td>
                                            <td>{item.client_industry && item.client_industry.industry_name}</td>
                                            <td>{item.country_id && item.country_id.country_name}</td>
                                            <td>{item.client_status === "1" ? "Actif" : "Desactiver"}</td>
                                            <td>{item.client_logo && <img src={`${baseURLMedia}${item.client_logo}`} width={30} height={30} alt="logo country" />}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/clients/edit/${item.id_client}`} className='td-link edit me-2'>
                                                    <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id_client, item.client_name)}>
                                                    <MdDelete className='icon' />
                                                 </Link>
                                            </td>
                                        </tr> 
                                    </Fragment>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteClients(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
