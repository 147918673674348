import React, { Fragment, useEffect, useState } from 'react';
import { ServicesTraningChapters, ServicesTraningSections } from '../../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { getFileExtension, imageExtensions, readFileAsBase64, urlToBase64, verifyBase64Media, verifyBase64MediaImage, verifyBase64MediaVideo, videoExtensions } from '../../../../../../../../components/ExtractDate';
import { IoMdCloseCircle } from 'react-icons/io';
import { MdOutlineInsertComment } from 'react-icons/md';
import { baseURLMedia } from '../../../../../../../../services/BASE_URL';

export const EditChapter = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id_training, id_chapter, id_section } = useParams();
    const [errors, setErrors] = useState([]);
    const [section, setSection] = useState([]);
    const [selectDescMedia, setSelectDescMedia] = useState({
        active: false,
        index: null,
    });
    const [courseEditChapterPost, setCourseEditChapterPost] = useState({
        chapter: {
            chapter_name: "",
            chapter_description: "",
            id_section: "",
            media: [],
        }
    });

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        setCourseEditChapterPost({
            ...courseEditChapterPost,
            chapter: {
                ...courseEditChapterPost.chapter,
                [name]: value
            }
        });
    };


    // Add description to specific media when on click
    const handleOnChangeMediDescription = (e, index) => {
        const updatedMedia = [...courseEditChapterPost.chapter.media];
        updatedMedia[index] = {
            ...updatedMedia[index],
            description: e.target.value,
        };

        setCourseEditChapterPost({
            ...courseEditChapterPost,
            chapter: {
                ...courseEditChapterPost.chapter,
                media: updatedMedia,
            }
        });
    };


    // When to select media
    const handleSelectIndexMedia = (active, index) => {
        setSelectDescMedia({
            ...selectDescMedia,
            active: active,
            index: index,
        });
    };


    // Transform media to base64
    const handleFileOnChange = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileType = files[i].type.split('/')[0];
            const isImageOrVideo = fileType === 'image' || fileType === 'video';

            if (isImageOrVideo) {
                const base64Data = await readFileAsBase64(files[i]);
                setCourseEditChapterPost((item) => ({
                    ...item,
                    chapter: {
                        ...item.chapter,
                        media: [...item.chapter.media, {
                            media_file: base64Data,
                            description: ""
                        }]
                    }
                }));
            } else {
                console.error(`Unsupported file type: ${fileType}`);
            }
        }
    };

    // Remove media
    const removeMedia = (index) => {
        setCourseEditChapterPost((item) => {
            const updatedMedia = [...item.chapter.media];
            if (index >= 0 && index < updatedMedia.length) {
                updatedMedia.splice(index, 1);
            }
            return {
                ...item,
                chapter: {
                    ...item.chapter,
                    media: updatedMedia
                }
            };
        });
    };

    useEffect(() => {
        getSections();
        getChaptersById(); 
    }, []);

    // Get all sections
    const getSections = async () => {
        await ServicesTraningSections.get().then((res) => {
            setSection(res.data.Products);
        }).catch((err) => {
            return err
        })
    };
 

    // ================================================================================
    // ---------- Convertir automatiquement la media du format json de l'API ----------
    // ================================================================================
    const getChaptersById = async () => {
        await ServicesTraningChapters.getById(id_chapter).then((res) => {
            const { chapter_description, chapter_name, media } = res.data.data;
            convertUnconvertedMedia(media).then((convertedMedia) => {
                setCourseEditChapterPost((item) => ({
                    ...item,
                    chapter: {
                        ...item.chapter,
                        chapter_description,
                        chapter_name,
                        media: convertedMedia
                    }
                }));
            });
        }).catch((err) => {
            console.error('Erreur lors de la récupération des chapitres', err);
        });
    };
    const convertUnconvertedMedia = async (media) => {
        const updatedMedia = await Promise.all(media.map(async (item) => {
            if (item.media_file && !item.media_file.startsWith('data:image') && !item.media_file.startsWith('data:video')) {
                // Si le fichier n'est pas déjà en base64, le convertir
                return new Promise((resolve) => {
                    urlToBase64(`${baseURLMedia}${item.media_file}`, (err, base64) => {
                        if (err) {
                            console.error('Erreur lors de la conversion du fichier en base64', err);
                            resolve(item); // Retourne l'élément sans modification en cas d'erreur
                        } else {
                            resolve({ ...item, media_file: base64 });
                        }
                    });
                });
            } else {
                // Si le fichier est déjà en base64, le retourner tel quel
                return item;
            }
        }));
        return updatedMedia;
    };

    const updatecChapters = async (e) => {
        e.preventDefault();

        setLoading(true);

        const chapter = courseEditChapterPost.chapter;

        var data = {
            chapter: {
                chapter_name: chapter.chapter_name.trim(),
                chapter_description: chapter.chapter_description,
                id_section: Number(id_section),
                media: chapter.media,
            }
        }; 

        await ServicesTraningChapters.put(Number(id_chapter), data).then((res) => {
            navigate(`/admin/trainings/${id_training}/section`);
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setErrors(err.response.data.errors);
        });

        setLoading(false);
    }; 
    
    return (
        <>
            <section id="admin-section-chapter-edit" className='admin-traning-form-all'>
                <div className='cusom-go-back-page mb-3'>
                    <Link to={`/admin/trainings/${id_training}/section`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update chapter</h4>
                    </div>
                </div>
                <form onSubmit={(e) => updatecChapters(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="chapter_name" className="form-label"> Chapter Name </label>
                        <input type="text" className={`form-control`} value={courseEditChapterPost.chapter && courseEditChapterPost.chapter.chapter_name} onChange={handleOnChange} name='chapter_name' />
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quiz_question_media" className="form-label">Add media :</label>
                        <input onChange={handleFileOnChange} name='media' multiple type="file" className={`form-control`} />
                     </div>
                    <div className='custom-display-media-description'>
                        <div className='custom-display-media-without-description'>
                            {
                                (courseEditChapterPost.chapter && courseEditChapterPost.chapter.media.length !== 0) && courseEditChapterPost.chapter.media.map((item, index) => (
                                    <Fragment key={index}>
                                        <div className='content'>
                                            <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} />
                                            <div className='description' onClick={() => handleSelectIndexMedia(true, index)}>
                                                <span>Description</span>
                                                <MdOutlineInsertComment className='icon-comment' />
                                            </div>
                                            {
                                                verifyBase64Media(item.media_file) ? (
                                                    <>
                                                        {
                                                            verifyBase64MediaImage(item.media_file) && (
                                                                <>
                                                                    <img src={`${item.media_file}`} title='Cliquer pour cacher la description' alt="Base64 Images" onClick={() => { handleSelectIndexMedia(false, index); }} />
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            verifyBase64MediaVideo(item.media_file) && (
                                                                <>
                                                                    <video controls>
                                                                        <source src={`${item.media_file}`} />
                                                                        Votre navigateur ne supporte pas la balise vidéo.
                                                                    </video>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                ) : (
                                                    <>
                                                        {
                                                            imageExtensions.includes(getFileExtension(item.media_file)) && (
                                                                <>
                                                                    <img src={`${baseURLMedia}${item.media_file}`} title='Cliquer pour cacher la description' alt='images' />
                                                                </>
                                                            )
                                                        }
                                                        {
                                                            videoExtensions.includes(getFileExtension(item.media_file)) && (
                                                                <>
                                                                    <video width="640" height="360" controls>
                                                                        <source src={`${baseURLMedia}${item.media_file}`} />
                                                                        Votre navigateur ne supporte pas la balise vidéo.
                                                                    </video>
                                                                </>
                                                            )
                                                        }
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </div>
                        {
                            selectDescMedia.active && (
                                <>
                                    <div className="my-3 sub-content">
                                        <textarea  value={courseEditChapterPost.chapter && courseEditChapterPost.chapter.media[selectDescMedia.index].description} className={`form-control`} onChange={(e) => handleOnChangeMediDescription(e, selectDescMedia.index)} rows="5" ></textarea>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Section  : </label>
                        <div className='d-flex flex-column w-100'>
                            <select className={`form-select`} onChange={handleOnChange} value={id_section} name='id_section' disabled>
                                {
                                    section.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option value={item.id_section}>{item.sections_name}</option>
                                        </Fragment>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="description" className="form-label"> Description : </label>
                        <textarea value={courseEditChapterPost.chapter && courseEditChapterPost.chapter.chapter_description} className={`form-control`} onChange={handleOnChange} name="chapter_description" rows="5" ></textarea>
                     </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate(`/admin/trainings/${id_training}/section`) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};

