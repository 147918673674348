export class ServiceSessions {

    static isAdmin = () =>{
        // let connect = false
        let apiKeys = this.getApiKeys()
        if (apiKeys){
            let levels = this.getLevels()
            if (levels === "administrators" || levels === "validators"){
                return true
            }
        }
    }

    static saveAuth = (id, is_superuser, name, profile_picture, is_staff, is_active, token, date_expiration, login_status) => {
        localStorage.setItem('id', id);
        localStorage.setItem('is_superuser', is_superuser);
        localStorage.setItem('name', name);
        localStorage.setItem('profile_picture', profile_picture);
        localStorage.setItem('is_staff', is_staff);
        localStorage.setItem('is_active', is_active);
        localStorage.setItem('token', token);
        localStorage.setItem('date_expiration', date_expiration);
        localStorage.setItem('login_status', login_status);
    }

    static getIdUsers = () => {
        return localStorage.getItem('id');
    }

    static getSuperUser = () => {
        return localStorage.getItem('is_superuser');
    }

    static getName = () => {
        return localStorage.getItem('name');
    }

    static getProfilePicture = () => {
        return localStorage.getItem('profile_picture');
    }

    static getIsStaff = () => {
        return localStorage.getItem('is_staff');
    }

    static getToken = () => {
        return localStorage.getItem('token');
    }

    static getDateExpire = () => {
        return localStorage.getItem('date_expiration');
    }

    static getLoginStatus = () => {
        return localStorage.getItem('login_status');
    }

    static logout = () => {
        // localStorage.clear();
        localStorage.removeItem('id');
        localStorage.removeItem('is_active');
        localStorage.removeItem('is_superuser');
        localStorage.removeItem('name');
        localStorage.removeItem('profile_picture');
        localStorage.removeItem('is_staff');
        localStorage.removeItem('token');
        localStorage.removeItem('date_expiration');
        localStorage.removeItem('login_status');

    }
}
