import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettingsCounties, ServicesSettingsLevelOfStudy } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditLevelOfStudies = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id_level_of_study } = useParams();
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [selectedLevel, setSelectedLevel] = useState(false);
    const [privilegeUpdate, setPrivilegeUpdate] = useState({
        level_name: "",
        id_country: "",
    })

    useEffect(() => {
        getLevelOfStudentById();
        getCountries();
    }, []);

    const handleOnChange = (e) => {

        let optionItems = e.target.value;

        setPrivilegeUpdate({
            ...privilegeUpdate,
            [e.target.name]: e.target.value
        });

        if (optionItems === 'Other') {
            setSelectedLevel(true);
        }

        if (optionItems === 'BAC' || optionItems === 'Licence' || optionItems === 'Maitrise') {
            setSelectedLevel(false);
        }
    }

    const getLevelOfStudentById = async () => {
        await ServicesSettingsLevelOfStudy.getById(id_level_of_study).then((res) => {
            setPrivilegeUpdate({
                level_name: res.data.data.level_name,
                id_country: res.data.data.id_country,
            });
        }).catch((err) => {
            return err
        });
    }


    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    const updateLevelOfStudent = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_privilege', id_level_of_study)
        forms.append('level_name', privilegeUpdate.level_name)
        forms.append('id_country', privilegeUpdate.id_country)

        await ServicesSettingsLevelOfStudy.put(id_level_of_study, forms).then((res) => {
            navigate('/admin/settings/level-of-study');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/level-of-study`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update level of study</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateLevelOfStudent(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} value={privilegeUpdate.id_country} onChange={handleOnChange} name='id_country'>
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${privilegeUpdate.id_country === "" || privilegeUpdate.id_country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="password2" className="form-label">Level  </label>
                        <div className='d-flex flex-column w-100'>
                            <select className={`form-select`} onChange={handleOnChange} value={privilegeUpdate.level_name} name={!selectedLevel && `level_name`}>
                                <option>{privilegeUpdate.level_name}</option>
                                <option value='CEPE'>CEPE</option>
                                <option value='BEPEC'>BEPEC</option>
                                <option value='BAC'>BAC</option>
                                <option value='Licence'>Licence</option>
                                <option value='Maitrise'>Maitrise</option>
                                <option value='Other'>Other</option>
                            </select>
                            <small className={`${privilegeUpdate.level_name === "" || privilegeUpdate.level_name === "Selected level" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                        </div>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/level-of-study") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
