import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Clients } from '../views/Clients'; 
import { UpdateClient } from '../views/Update';
import { CreateClient } from '../views/Create';

export const ClientsControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Clients />} />
                <Route exact path='/add' element={<CreateClient />} />
                <Route exact path='/edit/:id_client' element={<UpdateClient />} />
            </Routes>
        </>
    );
};
