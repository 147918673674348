import React, { Fragment, useState } from 'react';
import { Link, Outlet, useLocation } from 'react-router-dom';
import { PiSquaresFour, PiUserSquareLight } from "react-icons/pi"; 
import { CiUser } from "react-icons/ci";
import { MdContentCopy, MdOutlineCampaign, MdOutlineLocationOn } from "react-icons/md";
import { AiOutlineStar, AiOutlineSetting } from "react-icons/ai";
import { PiTarget, PiPresentationChartThin } from "react-icons/pi";
import { RiAccountBoxLine } from 'react-icons/ri';
import { IoIosLogOut } from "react-icons/io";
import { logo, userEmpty } from '../../assets/images/ExportsImages';
import { ServicesAuthentication } from '../../services/api/ServicesAuths';
import { FaRegMap } from "react-icons/fa6"; 
import { ModelTemplapleSimple } from '../CustomModal';
import { KCustomButtonDouble } from '../Button';


export const SideBar = (props) => {

    const url = `/admin/`;
    let location = useLocation().pathname;
    const [showModalLogout, setShowModalLogout] = useState(false);
    const [loadData, SetLoadData] = useState(false);

    const menu = [
        {
            name: "Dashboard",
            path: "homes",
            sub_navbar: null,
            icon: <PiSquaresFour className='icon' />,
            location_path: url,
        },
        {
            name: "Clients Management",
            path: "clients",
            icon: <CiUser className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Users Management",
            path: "users",
            icon: <PiUserSquareLight className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Area",
            path: "area",
            icon: <FaRegMap className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Products Management",
            path: "products",
            icon: <MdContentCopy className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "FieldSoliders Management",
            path: "foot-soldiers",
            icon: <AiOutlineStar className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Targets Management",
            path: "targets",
            icon: <PiTarget className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Traning Management",
            path: "trainings",
            icon: <PiPresentationChartThin className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "POS Management",
            path: "pos",
            icon: <MdOutlineLocationOn className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "Settings Management  ",
            path: "settings/country",
            icon: <AiOutlineSetting className='icon' />,
            sub_navbar: "settings/",
            location_path: url,
        },
        {
            name: "Campaign management",
            path: "campaign",
            icon: <MdOutlineCampaign className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
        {
            name: "My Account",
            path: "accounts",
            icon: <RiAccountBoxLine className='icon' />,
            sub_navbar: null,
            location_path: url,
        },
    ]

    const logout_ = async () => {
        SetLoadData(true);
        await ServicesAuthentication.logout()
        SetLoadData(false);
    };


    return (
        <>
            <section id='field36-content-admin'>
                <div id='sidebar' className={`sidebar`}>
                    <nav className='sidebar-content'>
                        <Link to='/admin/homes' className='logo'>
                            <span onClick={props.toggleSidebarMenu} className='logo'>
                                <img src={logo} width={60} height={50} alt='ppp' />
                            </span>
                        </Link>
                        <div className="sd-body">
                            <ul className='navbar-nav'>
                                {
                                    menu.map((item, index) => (
                                        <Fragment key={`unique-${item.name}`}>
                                            <li className='item-li' key={`unique-${item.index}`}>
                                                <Link to={item.path} className={`${(location.startsWith(item.location_path + item.path) || location.startsWith(item.location_path + item.sub_navbar)) && "active"} item-link`}>{item.icon} {item.name}</Link>
                                            </li>
                                        </Fragment>
                                    ))
                                }
                                <li className='item-li'>
                                    <Link to={`#`} onClick={() => { setShowModalLogout(true) }} className='item-link'> <IoIosLogOut className='icon' /> Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </nav>
                </div> 
                <div id='navbar' className=''>
                    <nav className="navbar">
                        <div className="content-navbar">
                            <span>Main plateform</span>

                            <div className="left">
                                <span className=''>Welcome, Gérard</span>
                                <img src={userEmpty} width={40} height={40} alt='user_photo' />
                            </div>
                        </div>
                    </nav>

                    <div className='full-content'>
                        <div className='card-custom'>
                            <Outlet />
                        </div>
                    </div>
                </div>
            </section>

            <ModelTemplapleSimple show={showModalLogout} hide={() => { setShowModalLogout(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <p className='mb-4'>Souhaitez-vous vous déconnecter ?</p>
                        <KCustomButtonDouble
                            loading={loadData}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalLogout(false) }}
                            clickr={() => logout_()}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-logout'} />
        </>
    );
};
