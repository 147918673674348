import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Campaigns } from '../views/Campaigns';  
import { CreateCampaigns } from '../views/Create';
import { UpdateCampaigns } from '../views/Update';


export const CampaignControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Campaigns />} />
                <Route exact path='/create' element={<CreateCampaigns />} />
                <Route exact path='/update/:id_campaign' element={<UpdateCampaigns />} />
            </Routes>
        </>
    );
};
