import React from 'react'; 
import { SideBar } from './SideBar';

export const SetSideBar = () => {
    return (
        <>
            <SideBar />
        </>
    );
};
