import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Products } from '../views/Products';
import { CreateProducts } from '../views/Create';
import { UpdateProducts } from '../views/Update';


export const ProductsControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Products />} />
                <Route exact path='add' element={<CreateProducts />} />
                <Route exact path='edit/:id_products' element={<UpdateProducts />} />
            </Routes>
        </>
    );
};
