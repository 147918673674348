import { BASE_URL } from "../BASE_URL";
import { ServiceSessions } from "../ServicesSession";

export class ServicesArea {

    static url_areas = 'area_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_areas, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static detail = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_areas}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static create = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_areas, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static update = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_areas}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_areas}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}



export class ServicesTerritory {

    static url_areas = 'territory_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_areas, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_areas}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_areas, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_areas}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_areas}${id}/`, {
                headers: { 
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}