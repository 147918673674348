import { BASE_URL } from "../BASE_URL";
import { ServiceSessions } from "../ServicesSession";

export class ServicesSettingsCounties {

    static url_countries = 'country_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_countries, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_countries}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_countries, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_countries}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_countries}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesSettingsLocality {

    static url = 'locality_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesSettingsLevelOfStudy {

    static url_level_of_study = 'education_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_level_of_study, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_level_of_study}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_level_of_study, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_level_of_study}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_level_of_study}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesSettingsTypeID {

    static url_type_id = 'typeid_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_type_id, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_type_id}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_type_id, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_type_id}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_type_id}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesSettinPrivileges {

    static url_privileges = 'privilege_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_privileges, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_privileges}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_privileges, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_privileges}${id}/`, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_privileges}${id}/`, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}


export class ServicesSettingRoles {

    static url_roles = 'role_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_roles, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_roles}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_roles, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_roles}${id}/`, data, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_roles}${id}/`, {
                headers: {
                    'content-type': 'application/json',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}

export class ServicesSettingsIndustries {

    static url_industries = 'industry_api_v1/';

    static get = async () => {
        try {
            const response = await BASE_URL.get(
                this.url_industries, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                },
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static getById = async (id) => {
        try {
            const response = await BASE_URL.get(
                `${this.url_industries}${id}/`, {
                headers: {
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`,
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static post = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_industries, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static put = async (id, data) => {
        try {
            const response = await BASE_URL.put(
                `${this.url_industries}${id}/`, data, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static delete = async (id) => {
        try {
            const response = await BASE_URL.delete(
                `${this.url_industries}${id}/`, {
                headers: {
                    'content-type': 'multipart/form-data',
                    'Accept': 'application/json',
                    'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response.data;
        } catch (error) {
            throw error;
        }
    }
}
