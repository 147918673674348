import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Area } from '../views/Area'; 
import { CreateArea } from '../views/Create';

export const AreaControllers = () => {
    return (
        <>
             <Routes>
                <Route exact index element={<Area />} />
                <Route exact path='add' element={<CreateArea />} />
            </Routes>
        </>
    );
};