import React, { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditCountry = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();

    const { id_country } = useParams();
    const [errors, setErrors] = useState([]);

    const [countriesUpdate, setCountriesUpdate] = useState({
        country_name: "",
        country_prefixe: "",
        flag: "",
        // numbers_of_clients: "",
    })


    useEffect(() => {
        getCountriesById();
    }, []);

    const handleOnChange = (e) => {
        setCountriesUpdate({
            ...countriesUpdate,
            [e.target.name]: e.target.value
        });
    }

    const handleFileOnChange = (e) => {
        setCountriesUpdate({
            ...countriesUpdate,
            [e.target.name]: e.target.files[0]
        });
    }

    const getCountriesById = async () => {
        await ServicesSettingsCounties.getById(id_country).then((res) => {
            const { country_name, country_prefixe, flag, numbers_of_clients } = res.data.data;
            setCountriesUpdate({
                country_name,
                country_prefixe,
                flag,
                numbers_of_clients,
            });
        }).catch((err) => {
            return err
        });
    }

    const updateCountries = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_country', id_country)
        forms.append('country_name', countriesUpdate.country_name)
        forms.append('country_prefixe', countriesUpdate.country_prefixe)
        forms.append('flag', countriesUpdate.flag)

        await ServicesSettingsCounties.put(id_country, forms).then((res) => {
            navigate('/admin/settings/country');
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.response.data.message);
            setErrors(err.response.data.errors);
        });

        setLoading(false);
    };



    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/country`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update country ({countriesUpdate.country_name})</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updateCountries(e)}>
                    <div className="mb-3">
                        <label htmlFor="flag" className="form-label">Flag </label>
                        <input onChange={handleFileOnChange} name='flag' type="file" className={`form-control`} id="flag" />
                        <small className={`${errors.flag && (errors.flag[0] || countriesUpdate.flag === '') ? "errors-fields" : "d-none"}`}>{errors.flag}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Country</label>
                        <input onChange={handleOnChange} value={countriesUpdate.country_name} name='country_name' type="text" className={`form-control`} id="currency" />
                        <small className={`${errors.country_name && (errors.country_name[0] || countriesUpdate.country_name === '') ? "errors-fields" : "d-none"}`}>{errors.country_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="currency" className="form-label">Phone code </label>
                        <input onChange={handleOnChange} value={countriesUpdate.country_prefixe} name='country_prefixe' type="text" className={`form-control`} id="currency" />
                        <small className={`${errors.country_prefixe && (errors.country_prefixe[0] || countriesUpdate.country_prefixe === '') ? "errors-fields" : "d-none"}`}>{errors.country_prefixe}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/country") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};

//{countriesUpdate.flag && <img src={URL.createObjectURL(countriesUpdate.flag)} alt="Flag" style={{ maxWidth: '100px', marginTop: '10px' }} />}
