import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { ValidateKYC } from '../views/Validate';
import { UpdateKYC } from '../views/forms/UpdateKYC';
import { PendingKYC } from '../views/Pending';

export const FootSoldiersControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<ValidateKYC />} />
                <Route exact path='pending' element={<PendingKYC />} />
                <Route exact path='validate/:id_footsolidiers' element={<UpdateKYC />} />
            </Routes>
        </>
    );
};
