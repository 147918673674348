import React, { Fragment, useEffect, useState } from 'react';
import { KCustomButtonDouble } from '../../../../components/Button';
import { Link, useNavigate } from 'react-router-dom';
import { IoChevronBackSharp } from 'react-icons/io5';
import { toast } from 'react-toastify';
import { ServicesCampaigns } from '../../../../services/api/ServocesCampaigns';
import { ServicesProducts } from '../../../../services/api/ServicesProducts';
import { IoIosClose } from 'react-icons/io';
import { ServicesTerritory } from '../../../../services/api/ServicesAera';
import { urlCampaign } from './Campaigns';

export const CreateCampaigns = () => {

    const [products, setProducts] = useState([]);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [territories, setTerritories] = useState([]);
    const [errors, setErrors] = useState([]);
    const [campaign, setCampaign] = useState({
        product: [], // id product type number
        name: '',
        campaign_type: '',
        action_type: '',
        input_description: '',
        process_operationnel: '',
        start_date: '',
        end_date: '',
        illustrative_examples: '',
        territory: [] // id territory type number
    });
    const dataCampagnesTypes = [
        { id: 1, name: "Email" },
    ];
    const dataTypesActions = [
        { id: 1, name: "Promotion" },
    ];
    const processusOperationnels = [
        { id: 1, name: "Gestion des achats" },
        { id: 2, name: "Gestion des stocks" },
        { id: 3, name: "Production / Fabrication" },
        { id: 4, name: "Gestion des commandes" },
        { id: 5, name: "Logistique et distribution" },
        { id: 6, name: "Gestion de la relation client (CRM)" },
        { id: 7, name: "Gestion des ressources humaines" },
        { id: 8, name: "Gestion de la qualité" },
        { id: 9, name: "Gestion de la maintenance" },
        { id: 10, name: "Gestion des finances" },
        { id: 11, name: "Gestion des projets" },
        { id: 12, name: "Recherche et développement (R&D)" },
        { id: 13, name: "Gestion de la sécurité" },
        { id: 14, name: "Gestion de la chaîne d'approvisionnement (Supply Chain Management)" },
        { id: 15, name: "Marketing et communication" }
    ];

    useEffect(() => {
        document.title = "Campaign/add";
    });

    useEffect(() => {
        getProduct();
        getTerritory();
    }, []);

    const handleOnChange = (e) => {
        const { name, value } = e.target;
        if (name === 'product') {
            if (!campaign.product.includes(value)) {
                setCampaign({
                    ...campaign,
                    product: [...campaign.product, Number(value)]
                });
            }
        } else if (name === 'territory') {
            if (!campaign.territory.includes(value)) {
                setCampaign({
                    ...campaign,
                    territory: [...campaign.territory, Number(value)]
                });
            }
        } else {

            setCampaign({
                ...campaign,
                [name]: value
            });
        }

    }


    const removePrivilege = (value) => {
        setCampaign({
            ...campaign,
            product: campaign.product.filter(item => item !== value)
        });
    };

    const removeTerritory = (value) => {
        setCampaign({
            ...campaign,
            territory: campaign.territory.filter(item => item !== value)
        });
    };

    // Get all products
    const getProduct = async () => {
        await ServicesProducts.get().then((res) => {
            setProducts(res.data.Products);
        }).catch((err) => {
            return err
        })
    };


    // Get all Territories 
    const getTerritory = async () => {
        await ServicesTerritory.get().then((res) => {
            setTerritories(res.data.territories);
        }).catch((err) => {
            return err
        })
    };


    const createCampaign = async (e) => {
        e.preventDefault();
        setLoading(true);
        await ServicesCampaigns.post(campaign).then((res) => {
            navigate(urlCampaign);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message); 
        });
        setLoading(false);
    };

    return (
        <>
            <section id="admin-campaign-create">
                <div className='cusom-go-back-page'>
                    <Link to={urlCampaign} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add  campaign</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createCampaign(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Name </label>
                        <input onChange={handleOnChange} name='name' type="text" className="form-control" placeholder="" />
                        <small className={`${campaign.name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Campaign type</label>
                        <select className={`form-select`} onChange={handleOnChange} name='campaign_type' >
                            <option>Select a campaign type</option>
                            {
                                dataCampagnesTypes.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.name}>{item.name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.campaign_type === "" || campaign.campaign_type === "Select a campaign type" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Action type</label>
                        <select className={`form-select`} onChange={handleOnChange} name='action_type' >
                            <option>Select a action type</option>
                            {
                                dataTypesActions.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.name}>{item.name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.action_type === "" || campaign.action_type === "Select a action type" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Description </label>
                        <textarea className={`form-control`} onChange={handleOnChange} name="input_description" rows="5" ></textarea>
                        <small className={`${campaign.input_description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Product</label>
                        <select className={`form-select`} onChange={handleOnChange} name='product' >
                            <option>Select a product</option>
                            {
                                products.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_product}>{item.product_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.product === "" || campaign.product === "Select a product" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {products
                                .filter(item => campaign.product.includes(item.id_product)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.product_name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removePrivilege(item.id_product)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Territory</label>
                        <select className={`form-select`} onChange={handleOnChange} name='territory' >
                            <option>Select territory</option>
                            {
                                territories.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id}>{item.area_territory_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${campaign.territory === "" || campaign.territory === "Select territory" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-2">
                        <div className='item-selected'>
                            {territories
                                .filter(item => campaign.territory.includes(item.id)).map((item, index) => (
                                    <Fragment key={index}>
                                        <span>{item.area_territory_name} <IoIosClose title='Cliquer pour supprimer' onClick={() => removeTerritory(item.id)} className='icon' /></span>
                                    </Fragment>
                                ))
                            }
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Exemple d'illustration </label>
                        <textarea className={`form-control`} onChange={handleOnChange} name="illustrative_examples" rows="5" ></textarea>
                        <small className={`${campaign.illustrative_examples === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Start date </label>
                        <input type="date" className="form-control" onChange={handleOnChange} name='start_date' placeholder="" />
                        <small className={`${campaign.start_date === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> End date </label>
                        <input type="date" className="form-control" onChange={handleOnChange} name='end_date' placeholder="" />
                        <small className={`${campaign.end_date === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(urlCampaign) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
