import React, { useState } from 'react';
import { ServicesSettinPrivileges } from '../../../../../services/api/ServicesSettings';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { KCustomButtonDouble } from '../../../../../components/Button';
 import { IoChevronBackSharp } from 'react-icons/io5';


export const AddPrivileges = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [privilegePost, setPrivilege] = useState({
        name: "",
        description: "",
    })

    const handleOnChange = (e) => {
        setPrivilege({
            ...privilegePost,
            [e.target.name]: e.target.value
        });
    }


    const createPrivilege = async (e) => {
        e.preventDefault();
        setLoading(true);

        var data = {
            name: privilegePost.name,
            description: privilegePost.description
        }

        await ServicesSettinPrivileges.post(JSON.stringify(data)).then((res) => {
            navigate('/admin/settings/privilege');
            toast.success("privilege create with successfull")
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/privilege`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add privilege</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createPrivilege(e)}>
                    <div className="mb-3">
                        <label htmlFor="name_industry" className="form-label">Name  </label>
                        <input type="text" onChange={handleOnChange} name='name' className={`form-control`} id="name_industry" />
                        <small className={`${privilegePost.name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name_industry" className="form-label">Description  </label>
                        <input type="text" onChange={handleOnChange} name='description' className={`form-control`} id="name_industry" />
                        <small className={`${privilegePost.description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/privilege") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
