import React, { useEffect, useState } from 'react';
import { IoChevronBackSharp } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../../../components/Button';
import { ServicesTraningExams } from '../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';

export const ExamsEdit = () => {

    const [loading, setLoading] = useState(false);
    const [, ] = useState([]); 
    const [errors, setErrors] = useState([]);
    const { id_training, id_exam } = useParams();  
    const navigate = useNavigate();
    const [examsUpdate, setExamsUpdate] = useState({
        exam_order: "",
        exam_name: "",
        exam_description: "",
        id_training: Number(id_training),
    })

    const handleOnChange = (e) => {
        setExamsUpdate({
            ...examsUpdate,
            [e.target.name]: e.target.value
        });
    }
 
    useEffect(() => {
        getExamsId();
    },[]);

    
    const getExamsId = async () => {
        await ServicesTraningExams.getById(id_exam).then((res) => {  
            const data = res.data.data;
            setExamsUpdate({
                exam_order: data.exam_order,
                exam_name: data.exam_name,
                exam_description: data.exam_description,
                id_training: id_training,
            });
        }).catch((err) => {
            return err
        });
    } 


    const updateExams = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('exam_order', examsUpdate.exam_order)
        forms.append('exam_name', examsUpdate.exam_name)
        forms.append('exam_description', examsUpdate.exam_description)
        forms.append('id_training', Number(id_training))

        await ServicesTraningExams.put(id_exam, forms).then((res) => {
            navigate(`/admin/trainings/${id_training}/exam`);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
       <>
       <section id="admin-traning-exams-add" className='admin-traning-form-all'>
           <div className='cusom-go-back-page'>
               <Link to={`/admin/trainings/${id_training}/exam`} className='link-btn-custom'>
                   <button type="button" className="btn-custom-primary-icon">
                       <IoChevronBackSharp className='icon' />
                       <span>Retour</span>
                   </button>
               </Link>
               <div className='grid-custom-one-column'>
                   <h4 className='title'>Update exam</h4>
               </div>
           </div>
           <form className='custom-form mt-5' onSubmit={(e) => updateExams(e)}> 
               <div className="mb-3">
                   <label htmlFor="" className="form-label">Order</label>
                   <input onChange={handleOnChange} value={examsUpdate.exam_order} name='exam_order' type="number" className="form-control" placeholder="" />
                   <small className={`${examsUpdate.exam_order === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
               </div>
               <div className="mb-3">
                   <label htmlFor="" className="form-label">Name</label>
                   <input onChange={handleOnChange} value={examsUpdate.exam_name} name='exam_name' type="text" className="form-control" placeholder="" />
                   <small className={`${examsUpdate.exam_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
               </div>
               <div className="mb-3">
                   <label htmlFor="" className="form-label">Description</label>
                   <input onChange={handleOnChange} value={examsUpdate.exam_description} name='exam_description' type="text" className="form-control" placeholder="" />
                   <small className={`${examsUpdate.exam_description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
               </div>
               <br />
                <KCustomButtonDouble
                       loading={loading}
                       textel={`Annuler`}
                       texter={`Update`}
                       clickl={() => { navigate(`/admin/trainings/${id_training}/exam`) }}
                       clickr={() => {}}
                       typel={`button`}
                       typer={`submit`} />
           </form>
       </section>
       </> 
    );
};

