import React from 'react';
import ReactApexChart from 'react-apexcharts';

export const Bars = () => {

    const barChart = {
        options: {
            chart: {
                type: "bar",
                height: 109,
                stacked: !0,
                toolbar: {
                    show: !1
                }
            },
            plotOptions: {
                bar: {
                    horizontal: !1,
                    columnWidth: "30%",
                    borderRadius: 6
                }
            },
            dataLabels: { enabled: !1 },
            legend: {
                show: !0,
                position: "bottom",
                horizontalAlign: "center",
                fontWeight: 400,
                fontSize: "8px",
                offsetX: 0,
                offsetY: 0,
                markers: {
                    width: 9,
                    height: 9,
                    radius: 4
                }
            },
            stroke: {
                show: !0,
                width: 2,
                colors: ["transparent"]
            },
            grid: {
                show: !0,
                borderColor: '#e0e0e0', // Ajouter une couleur de bordure pour la grille
                padding: {
                    left: 0,
                    right: 0,
                }
            },
            colors: ['#FFA500'],
            xaxis: {
                categories: ["Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"],
                axisTicks: { show: !1 },
                axisBorder: {
                    show: !0,
                    strokeDashArray: 1,
                    height: 1,
                    width: "100%",
                    offsetX: 0,
                    offsetY: 0
                },
            },
            yaxis: {
                show: true,
                // min: 0, // Définir la valeur minimale de l'axe vertical à 0
                // max: 50, // Définir la valeur maximale de l'axe vertical à 50
                // tickAmount: 10, // Spécifier le nombre de ticks (intervalles) souhaité
                // labels: {
                //     formatter: (value) => `${value.toFixed(1)}`, // Formatter les étiquettes avec une décimale
                // }
            },
            fill: { opacity: 1 },
        },
        series: [
            { name: "Current Year", data: [36.2, 22.4, 38.2, 30.5, 26.4, 30.4, 10.9] },
        ],
    }

    return (
        <>
            <ReactApexChart options={barChart.options} series={barChart.series} type="bar" height={250} />
        </>
    );
};
