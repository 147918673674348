import React, { Fragment, useEffect, useState } from 'react';
import { ServicesTraningChapters, ServicesTraningSections } from '../../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { KCustomButtonDouble } from '../../../../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';
import { IoMdCloseCircle } from "react-icons/io";
import { readFileAsBase64, verifyBase64MediaImage, verifyBase64MediaVideo } from '../../../../../../../../components/ExtractDate';
import { MdOutlineInsertComment } from "react-icons/md";


export const AddChapter = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const { id_training, id_section } = useParams();
    const [errors, setErrors] = useState([]);
    const [selectDescMedia, setSelectDescMedia] = useState({
        active: false,
        index: null,
    });
    const [section, setSection] = useState([]);
    const [courseAddChapterPost, setCourseAddChapterPost] = useState({
        chapter: {
            chapter_name: "",
            chapter_description: "",
            id_section: "",
            media: [],
        }
    });

    const handleOnChange = (e) => {
        setCourseAddChapterPost({
            ...courseAddChapterPost,
            chapter: {
                ...courseAddChapterPost.chapter,
                [e.target.name]: e.target.value
            }
        });
    };

    // Add description to specific media when on click
    const handleOnChangeMediDescription = (e, index) => {
        const updatedMedia = [...courseAddChapterPost.chapter.media];
        updatedMedia[index] = {
            ...updatedMedia[index],
            description: e.target.value,
        };

        setCourseAddChapterPost({
            ...courseAddChapterPost,
            chapter: {
                ...courseAddChapterPost.chapter,
                media: updatedMedia,
            }
        });
    };

    // When to select media
    const handleSelectIndexMedia = (active, index) => {
        setSelectDescMedia({
            ...selectDescMedia,
            active: active,
            index: index,
        });
    };

    // Transform media to base64
    const handleFileOnChange = async (e) => {
        const files = e.target.files;
        for (let i = 0; i < files.length; i++) {
            const fileType = files[i].type.split('/')[0];
            const isImageOrVideo = fileType === 'image' || fileType === 'video';

            if (isImageOrVideo) {
                const base64Data = await readFileAsBase64(files[i]);
                setCourseAddChapterPost((item) => ({
                    ...item,
                    chapter: {
                        ...item.chapter,
                        media: [...item.chapter.media, {
                            media_file: base64Data,
                            description: ""
                        }]
                    }
                }));
            } else {
                console.error(`Unsupported file type: ${fileType}`);
            }
        }
    };

    // Remove media
    const removeMedia = (index) => {
        setCourseAddChapterPost((item) => {
            const updatedMedia = [...item.chapter.media];
            if (index >= 0 && index < updatedMedia.length) {
                updatedMedia.splice(index, 1);
            }
            return {
                ...item,
                chapter: {
                    ...item.chapter,
                    media: updatedMedia
                }
            };
        });
    };

    useEffect(() => {
        getSections();
    }, []);

    // Get all sections
    const getSections = async () => {
        await ServicesTraningSections.get().then((res) => {
            setSection(res.data.Products);
        }).catch((err) => {
            return err
        })
    };

    const createChapters = async (e) => {
        e.preventDefault();

        setLoading(true);

        const chapter = courseAddChapterPost.chapter;

        if (!chapter.chapter_name || !id_section) {
            console.error('chapter_name ou id_section manquant');
            toast.error('chapter_name ou id_section manquant')
            setLoading(false);
            return;
        }

        var data = {
            chapter: {
                chapter_name: chapter.chapter_name.trim(),
                chapter_description: chapter.chapter_description,
                id_section: Number(id_section),
                media: chapter.media,
            }
        };

        await ServicesTraningChapters.post(data).then((res) => {
            navigate('/admin/trainings/' + id_training + '/section');
            toast.success(res.data.message)
        }).catch((err) => { 
            toast.error(err.response.data.message);
            setErrors(err.response.data.errors);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-section-chapter-add" className='admin-traning-form-all'>
                <div className='cusom-go-back-page mb-3'>
                    <Link to={`/admin/trainings/${id_training}/section`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add a new chapter</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createChapters(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="chapter_name" className="form-label"> Chapter Name </label>
                        <input type="text" className={`form-control`} onChange={handleOnChange} name='chapter_name' />
                        <small className={`${errors.chapter_name && (errors.chapter_name[0] || (courseAddChapterPost.chapter && courseAddChapterPost.chapter.chapter_name === '')) ? "errors-fields" : "d-none"}`}>{errors.chapter_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="quiz_question_media" className="form-label">Add media </label>
                        <input onChange={handleFileOnChange} multiple name='media' type="file" className={`form-control ${errors.media && `erreur-field`}`} id="quiz_question_media" />
                        <small className={`${errors.media && (errors.media[0] || courseAddChapterPost.chapter.media === '') ? "errors-fields" : "d-none"}`}>{errors.media}</small>
                    </div>
                    <div className='custom-display-media-description'>
                        <div className='custom-display-media-without-description'>
                            {
                                (courseAddChapterPost.chapter && courseAddChapterPost.chapter.media.length !== 0) && courseAddChapterPost.chapter.media.map((item, index) => (
                                    <Fragment key={index}>
                                        <div className='content'>
                                            <IoMdCloseCircle className='icon-close' onClick={() => removeMedia(index)} />
                                            <div className='description' onClick={() => handleSelectIndexMedia(true, index)}>
                                                <span>Description</span>
                                                <MdOutlineInsertComment className='icon-comment' />
                                            </div>
                                            {
                                                verifyBase64MediaImage(item.media_file) && (
                                                    <>
                                                        <img src={`${item.media_file}`} title='Cliquer pour cacher la description' alt="Base64 Images" onClick={() => { handleSelectIndexMedia(false, index); }} />
                                                    </>
                                                )
                                            }
                                            {
                                                verifyBase64MediaVideo(item.media_file) && (
                                                    <>

                                                        <video controls>
                                                            <source src={`${item.media_file}`} />
                                                            Votre navigateur ne supporte pas la balise vidéo.
                                                        </video>
                                                    </>
                                                )
                                            }
                                        </div>
                                    </Fragment>
                                ))
                            }
                        </div>
                        {
                            selectDescMedia.active && (
                                <>
                                    <div className="my-3 sub-content">
                                        <textarea id="monTextarea" value={courseAddChapterPost.chapter && courseAddChapterPost.chapter.media[selectDescMedia.index].description} className={`form-control`} onChange={(e) => handleOnChangeMediDescription(e, selectDescMedia.index)} rows="5" ></textarea>
                                    </div>
                                </>
                            )
                        }
                    </div>
                    <div className="mb-3">
                        <label className="form-label">Section </label>
                        <div className='d-flex flex-column w-100'>
                            <select className={`form-select`} onChange={handleOnChange} value={id_section} name='id_section' disabled>
                                {
                                    section.map((item, index) =>
                                        <Fragment key={`unique-${index}`}>
                                            <option value={item.id_section}>{item.sections_name}</option>
                                        </Fragment>
                                    )
                                }
                            </select>
                        </div>
                    </div>
                    <div className="mb-3">
                        <label className="form-label"> Description </label>
                        <textarea className={`form-control ${errors.chapter_description && `erreur-field`}`} onChange={handleOnChange} name="chapter_description" rows="5" ></textarea>
                        <small className={`${errors.chapter_description && (errors.chapter_description[0] || courseAddChapterPost.chapter.chapter_description === '') ? "errors-fields" : "d-none"}`}>{errors.chapter_description}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(`/admin/trainings/${id_training}/section`) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
