import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettingsCounties, ServicesSettingsTypeID } from '../../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditIDType = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [countries, setCountries] = useState([]);
    const { id_id_type } = useParams();
    const [IDTypeUpdate, setIDTypeUpdate] = useState({
        id_name: "",
        id_country: "",
    })

    useEffect(() => {
        getTypeById();
        getCountries();
    }, []);

    const handleOnChange = (e) => {
        setIDTypeUpdate({
            ...IDTypeUpdate,
            [e.target.name]: e.target.value
        });
    }

    const getTypeById = async () => {
        await ServicesSettingsTypeID.getById(id_id_type).then((res) => {
            const { id_name, id_country } = res.data.data;
            setIDTypeUpdate({
                id_name,
                id_country,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    const put_type_id = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_industry', id_id_type)
        forms.append('id_name', IDTypeUpdate.id_name)
        forms.append('id_country', IDTypeUpdate.id_country)

        await ServicesSettingsTypeID.put(id_id_type, forms).then((res) => {
            navigate('/admin/settings/id-type');
            toast.success(res.data.message)
        }).catch((err) => {
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/id-type`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update ID Type</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => put_type_id(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} value={IDTypeUpdate.id_country} name='id_country'>
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${IDTypeUpdate.id_country === "" || IDTypeUpdate.id_country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">ID Type  </label>
                        <select className="form-select" onChange={handleOnChange} value={IDTypeUpdate.id_name} name='id_name'>
                            <option>Selected id type</option>
                            <option value='Passport'>Passport</option>
                            <option value='CNI'>CNI</option>
                        </select>
                        <small className={`${IDTypeUpdate.id_name === "" || IDTypeUpdate.id_name === "Selected id type" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/id-type") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
