import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify'; 
import { KCustomButtonDouble } from '../../../../../components/Button'; 
import { IoChevronBackSharp } from 'react-icons/io5';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';

export const AddCountry = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [countriesPost, setCountriesPost] = useState({
        country_name: "",
        country_prefixe: "",
        flag: "",
        // numbers_of_clients: "",
    })

    const handleOnChange = (e) => {
        setCountriesPost({
            ...countriesPost,
            [e.target.name]: e.target.value
        });
    }

    const handleFileOnChange = (e) => {
        setCountriesPost({
            ...countriesPost,
            [e.target.name]: e.target.files[0]
        });
    }

    const createCountries = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('country_name', countriesPost.country_name)
        forms.append('country_prefixe', countriesPost.country_prefixe)
        forms.append('flag', countriesPost.flag)
        // forms.append('numbers_of_clients', countriesPost.numbers_of_clients)

        await ServicesSettingsCounties.post(forms).then((res) => {
            navigate('/admin/settings/country');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/country`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add country</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createCountries(e)}>
                    <div className="mb-3">
                        <label htmlFor="flag" className="form-label">Flag </label>
                        <input onChange={handleFileOnChange} name='flag' type="file" className="form-control" id="flag" />
                        <small className={`${countriesPost.flag === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="country" className="form-label">Country</label>
                        <input onChange={handleOnChange} name='country_name' type="text" className={`form-control`} id="currency" />
                        <small className={`${errors.country_name && (errors.country_name[0] || countriesPost.country_name === '') ? "errors-fields" : "d-none"}`}>{errors.country_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="currency" className="form-label">Phone code </label>
                        <input onChange={handleOnChange} name='country_prefixe' type="text" className={`form-control`} id="currency" />
                        <small className={`${errors.country_prefixe && (errors.country_prefixe[0] || countriesPost.country_prefixe === '') ? "errors-fields" : "d-none"}`}>{errors.country_prefixe}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/country") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
