import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Login } from '../views/Login';

export const AuthControllers = () => {
    return (
        <>
            <Routes> 
                <Route exact path='login' element={<Login />} />
            </Routes>
        </>
    );
};