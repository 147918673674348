import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesProducts } from '../../../../services/api/ServicesProducts';
import { ServicesTranings } from '../../../../services/api/ServicesTrainings';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { ServicesSettingsCounties } from '../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const UpdateProducts = () => {

    const { id_products } = useParams();
    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [errors, setErrors] = useState([]);
    const [countries, setCountries] = useState([]);
    const [traning, setTraning] = useState([]);
    const navigate = useNavigate();
    const [productsUpdate, setProductsUpdate] = useState({
        product_picture: "",
        product_name: "",
        product_price: "",
        product_commission: "",
        country: "",
        client: "",
        training_p: "",
    })

    const handleFileOnChange = (e) => {
        setProductsUpdate({
            ...productsUpdate,
            [e.target.name]: e.target.files[0]
        });
    }

    const handleOnChange = (e) => {
        setProductsUpdate({
            ...productsUpdate,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        document.title = "Product/Edit";
    });

    useEffect(() => {
        getCountries();
        getClients();
        getTranings();
        getProductsById();
    }, []);

    const getProductsById = async () => {
        await ServicesProducts.getById(id_products).then((res) => {
            setProductsUpdate({
                client: res.data.data.client.id_client,
                country: res.data.data.country.id_country,
                product_commission: res.data.data.product_commission,
                product_name: res.data.data.product_name,
                product_picture: res.data.data.product_picture,
                product_price: res.data.data.product_price,
                training_p: res.data.data.training_p,
            });
        }).catch((err) => {
            return err
        });
    }

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };


    // Get all traning
    const getTranings = async () => {
        await ServicesTranings.get().then((res) => {
            setTraning(res.data.Training);
        }).catch((err) => {
            return err
        })
    };

    const uodateProduct = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_product', id_products)
        forms.append('product_picture', productsUpdate.product_picture)
        forms.append('product_name', productsUpdate.product_name)
        forms.append('product_price', productsUpdate.product_price)
        forms.append('product_commission', productsUpdate.product_commission)
        forms.append('country', productsUpdate.country)
        forms.append('client', productsUpdate.client)
        forms.append('training_p', productsUpdate.training_p)

        // parseInt
        await ServicesProducts.put(id_products, forms).then((res) => {
            navigate('/admin/products');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors)
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-products-update">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/products`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update product ({productsUpdate.product_name})</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => uodateProduct(e)}>

                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Product Name</label>
                        <input onChange={handleOnChange} name='product_name' value={productsUpdate.product_name} type="text" className="form-control" placeholder="" />
                        <small className={`${errors.product_name && (errors.product_name[0] || productsUpdate.product_name === '') ? "errors-fields" : "d-none"}`}>{errors.product_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} value={productsUpdate.country && productsUpdate.country} onChange={handleOnChange} name='country' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${productsUpdate.country === '' || productsUpdate.country === "Selected country" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} value={productsUpdate.client && productsUpdate.client} onChange={handleOnChange} name='client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${productsUpdate.client === '' || productsUpdate.client === "Selected client" ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Training </label>
                        <select className={`form-select`} value={productsUpdate.traning} onChange={handleOnChange} name='training_p' >
                            <option selected>Selected traning</option>
                            {
                                traning.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_training}>{item.training_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.training_p && (errors.training_p[0] || productsUpdate.training_p === '' || productsUpdate.training_p === "Selected traning") ? "errors-fields" : "d-none"}`}>{errors.training_p}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Picture </label>
                        <input onChange={handleFileOnChange} name='product_picture' type="file" className="form-control" placeholder="" />
                        <small className={`${errors.product_picture || productsUpdate.product_picture === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Price <small>FCFA</small> </label>
                        <input onChange={handleOnChange} value={productsUpdate.product_price} name='product_price' type="text" className="form-control" placeholder="" />
                        <small className={`${errors.product_price && (errors.product_price[0] || productsUpdate.product_price === '') ? "errors-fields" : "d-none"}`}>{errors.product_price}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Commissions <small>FCFA</small> </label>
                        <input onChange={handleOnChange} value={productsUpdate.product_commission} name='product_commission' type="number" className="form-control" placeholder="" />
                        <small className={`${errors.product_commission && (errors.product_commission[0] || productsUpdate.product_commission === '') ? "errors-fields" : "d-none"}`}>{errors.product_commission}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Update`}
                        clickl={() => { navigate("/admin/products") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};
