import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Users } from '../views/Users';
import { UpdateUsers } from '../views/Update';
import { CreateUsers } from '../views/Create';

export const UsersControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Users />} />
                <Route exact path='add' element={<CreateUsers />} />
                <Route exact path='edit/:id_users' element={<UpdateUsers />} />
            </Routes>
        </>
    );
};