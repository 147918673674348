import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesClients } from '../../../../../services/api/ServicesClients';
import { ServicesSettingsCounties } from '../../../../../services/api/ServicesSettings';
import { ServicesTargets } from '../../../../../services/api/ServicesTargets';
import { KCustomButtonDouble } from '../../../../../components/Button';
 import { IoChevronBackSharp } from 'react-icons/io5';

export const AddTargets = () => {

    const [loading, setLoading] = useState(false);
    const [client, setClient] = useState([]);
    const [countries, setCountries] = useState([]);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);
    const [targetPost, setTargetPost] = useState({
        target_countrie: "",
        target_client: "",
        sheet: "",
    })

    const handleOnChange = (e) => {
        setTargetPost({
            ...targetPost,
            [e.target.name]: e.target.value
        });
    }

    const handleFileOnChange = (e) => {
        setTargetPost({
            ...targetPost,
            [e.target.name]: e.target.files[0]
        });
    }

    useEffect(() => {
        getCountries();
        getClients();
    }, []);

    // Get all clients
    const getClients = async () => {
        await ServicesClients.get().then((res) => {
            setClient(res.data.Client);
        }).catch((err) => {
            return err
        })
    };

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    const createTargets = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('target_countrie', targetPost.target_countrie)
        forms.append('target_client', targetPost.target_client)
        forms.append('sheet', targetPost.sheet)

        await ServicesTargets.post(forms).then((res) => {
            navigate('/admin/targets');
            var success = `${res.data.message} Delete ${res.data.deleted_entries} Update ${res.data.updated_entries} Create ${res.data.created_entries}`;
            toast.success(success);
        }).catch((err) => {
            setErrors(err.response.data);
            toast.error(err.response.data);
        });

        forms.append('privilege_id', targetPost.privilege_id)
        setLoading(false);
    };


    return (
        <>
            <section id="admin-targets-create">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/targets`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add targets</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => createTargets(e)}>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Client name </label>
                        <select className={`form-select`} onChange={handleOnChange} name='target_client' >
                            <option>Selected client</option>
                            {
                                client.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_client}>{item.client_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.target_client && (targetPost.target_client === '' || targetPost.target_client === "Selected client") ? "errors-fields" : "d-none"}`}>{errors.target_client}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='target_countrie' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.target_countrie && (targetPost.target_countrie === "" || targetPost.target_countrie === "Selected country") ? "errors-fields" : "d-none"}`}>{errors.target_countrie}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Client logo </label>
                        <input onChange={handleFileOnChange} name='sheet' type="file" className="form-control" placeholder="" />
                        <small className={`${errors.sheet || targetPost.sheet === '' ? "errors-fields" : "d-none"}`}>{errors.sheet}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Validate`}
                        clickl={() => { navigate("/admin/targets") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
