import React, { Fragment } from 'react'; 
import { Link, useLocation } from 'react-router-dom';



export const MenuNavBarSettings = () => {
 

    const url = "/admin/settings/";
    let location = useLocation().pathname;
 
    const dataMenu = [
        {
            title: "countries", 
            path: "country",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Localities", 
            path: "locality",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Level studies", 
            path: "level-of-study",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "ID Type", 
            path: "id-type",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Privileges", 
            path: "privilege",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Roles", 
            path: "role",
            sub_navbar: null,
            location_path: url,
        },
        {
            title: "Industries", 
            path: "industry",
            sub_navbar: null,
            location_path: url,
        },
    ]

    return (
        <>
            <nav id='menu-setting'>
                <ul className="custom-navbar-nav">
                    {
                        dataMenu.map((item, index) => (
                            <Fragment key={`unique-${index}`}>
                                <li className="nav-item" key={`unique-${index}`}>
                                    <Link to={item.location_path+item.path} className={`nav-link ${location.startsWith(item.location_path + item.path) && 'active'}`}>{item.title}</Link>
                                </li>
                            </Fragment>
                        ))
                    }
                </ul>
            </nav>
        </>
    );
};