import React, { useState } from 'react';
import { IoChevronBackSharp } from 'react-icons/io5';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesTraningExams } from '../../../../../../../services/api/ServicesTrainings';
import { KCustomButtonDouble } from '../../../../../../../components/Button';

export const ExamsAdd = () => {

    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const { id_training } = useParams();
    const navigate = useNavigate();
    const [examsCreate, setExamsCreate] = useState({
        exam_order: "",
        exam_name: "",
        exam_description: "",
        id_training: Number(id_training),
    })

    const handleOnChange = (e) => {
        setExamsCreate({
            ...examsCreate,
            [e.target.name]: e.target.value
        });
    }

    const createExams = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('exam_order', examsCreate.exam_order)
        forms.append('exam_name', examsCreate.exam_name)
        forms.append('exam_description', examsCreate.exam_description)
        forms.append('id_training', Number(id_training))

        await ServicesTraningExams.post(forms).then((res) => {
            navigate(`/admin/trainings/${id_training}/exam`);
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section id="admin-traning-exams-add" className='admin-traning-form-all'>
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/trainings/${id_training}/exam/`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add exam</h4>
                    </div>
                </div>
                <form className='custom-form mt-5' onSubmit={(e) => createExams(e)}>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Order</label>
                        <input onChange={handleOnChange} name='exam_order' type="number" className="form-control" placeholder="" />
                        <small className={`${examsCreate.exam_order === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Name</label>
                        <input onChange={handleOnChange} name='exam_name' type="text" className="form-control" placeholder="" />
                        <small className={`${examsCreate.exam_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label">Description</label>
                        <textarea className={`form-control ${errors.chapter_description && `erreur-field`}`} onChange={handleOnChange} name='exam_description' rows="5" ></textarea>
                        <small className={`${examsCreate.exam_description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate(`/admin/trainings/${id_training}/exam/`) }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};

