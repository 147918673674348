import React, { Fragment, useEffect, useState } from 'react';
import { ServicesTraningChapters, ServicesTraningSections } from '../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { Link, useParams } from 'react-router-dom';
import { ModelTemplapleSimple } from '../../../../../../../components/CustomModal';
import { KCustomButtonDouble, KCustomButtonSimaple, KCustomButtonSimaple1 } from '../../../../../../../components/Button'; 
import { MdDelete, MdEdit } from 'react-icons/md';
import { baseURLMedia } from '../../../../../../../services/BASE_URL';
import { getFileExtension, imageExtensions, videoExtensions } from '../../../../../../../components/ExtractDate';
import { SpinnerData } from '../../../../../../../components/SpinnerData';

export const Sections = () => {


    const [section, setSection] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalSectionDelete, setShowModalSectionDelete] = useState(false);
    const [showModalChapterDelete, setShowModalChapterDelete] = useState(false);
    const [showModalAddSection, setShowModalAddSection] = useState(false);
    const [showModalEditSection, setShowModalEditSection] = useState(false);
    const [chapter, setChapter] = useState([]);
    const { id_training } = useParams();
    const [loadDataSection, SetLoadDataSection] = useState(true);
    const [idSection, setIdSection] = useState(null);
    const [addSection, setAddSection] = useState({
        sections_name: '',
        id_formation: id_training,
    });
    const [editSection, setEditSection] = useState({
        sections_name: "",
        id_formation: id_training,
    })
    const [showModalSectionDeleteData, setShowModalSectionDeleteData] = useState({
        "name": "",
        "id": ""
    });
    const [showModalChapterDeleteData, setShowModalChapterDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        getSectionsChapters();
        getChapters();
    }, []);

    // Get all sections
    const getSectionsChapters = async () => {
        await ServicesTraningSections.get().then((res) => {
            setSection(res.data.Products);
            SetLoadDataSection(false);
        }).catch((err) => {
            return err
        })
    };

    // Get all chapter
    const getChapters = async () => {
        await ServicesTraningChapters.get().then((res) => {
            setChapter(res.data.Chapter);
        }).catch((err) => {
            return err
        })
    };

    // Delete section
    const deleteTraningsSections = async (id) => {
        setLoading(true);
        await ServicesTraningSections.delete(id).then((res) => {
            setShowModalSectionDelete(false);
            getSectionsChapters();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };


    const deleteDataSection = (kid, kname) => {
        setShowModalSectionDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalSectionDelete(true);
    }

    // Delete chapter
    const deleteTraningsChapters = async (id) => {
        setLoading(true);
        await ServicesTraningChapters.delete(id).then((res) => {
            setShowModalChapterDelete(false);
            getChapters();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteDataChapter = (kid, kname) => {
        setShowModalChapterDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalChapterDelete(true);
    }


    // Post section
    const handleOnChangeAddSection = (e) => {
        setAddSection({
            ...addSection,
            [e.target.name]: e.target.value,
        });
    };

    const createSections = async (e) => {
        e.preventDefault();
        setLoading(true);

        const forms = new FormData();
        forms.append('sections_name', addSection.sections_name);
        forms.append('id_formation', Number(id_training));

        await ServicesTraningSections.post(forms).then((res) => {
            setShowModalAddSection(false)
            toast.success(res.data.message);
            setLoading(false);
            setAddSection({
                sections_name: '',
            });
            addSection.sections_name = '';
            getSectionsChapters();
        }).catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message);
        });
    };

    useEffect(() => {
        if (idSection) {
            getSectionsById();
        }
    }, [idSection]);

    // Update section
    const getSectionsById = async () => {
        await ServicesTraningSections.getById(idSection).then((res) => {
            const { sections_name, sections_order } = res.data.data;
            setEditSection({
                sections_name,
                sections_order,
            });
        }).catch((err) => {
            return err
        });
    }

    const handleShowModalEditSection = (id) => {
        setIdSection(id);
        setShowModalEditSection(true)
    }

    const handleOnChangeEditSection = (e) => {
        setEditSection({
            ...editSection,
            [e.target.name]: e.target.value
        });
    }

    const updateSections = async (e) => {
        e.preventDefault();
        setLoading(true);

        const forms = new FormData();
        forms.append('id_client', idSection)
        forms.append('sections_name', editSection.sections_name)
        forms.append('id_formation', Number(id_training))

        await ServicesTraningSections.put(idSection, forms).then((res) => {
            setShowModalEditSection(false);
            toast.success(res.data.message);
            setLoading(false);
            getSectionsChapters();
        }).catch((err) => {
            setLoading(false);
            toast.error(err.response.data.message);
        });
    };

    const onlySectionIdTraning = section.filter(item => item.id_formation && item.id_formation.id_training === Number(id_training));
 

    return (
        <>
            <section id='admin-traning-section'>
                <div className='grid-custom-one-column'>
                    <div className='custom-btn-header custom-end'>
                        <Link to='#' className='link-btn-custom' onClick={() => setShowModalAddSection(true)}>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add a new section`} />
                        </Link>
                    </div>
                </div>

                <div className='section-content-full'>
                    <div className='spinner-loading'>
                        {loadDataSection && (
                            <SpinnerData />
                        )}
                        {
                            !loadDataSection && onlySectionIdTraning.length === 0 && (
                                <>Aucune information disponible</>
                            )
                        }
                    </div>
                    {
                        onlySectionIdTraning.map((items, index) => (
                            <Fragment key={`unique-${items.id_section}`}>
                                <div className='section-content'>
                                    <div className='title-section'>
                                        <div className='item'>
                                            <span>Section {1 + index}</span>
                                            <strong>{items.sections_name && items.sections_name}</strong>
                                        </div>
                                        <div className='item'>
                                            <Link to={`#`} className='td-link edit me-2' onClick={() => handleShowModalEditSection(items.id_section)}>
                                                <MdEdit className='icon' />
                                            </Link>
                                            <Link to='#' className='td-link delete ms-2' onClick={() => deleteDataSection(items.id_section, items.sections_name)}>
                                                <MdDelete className='icon' />
                                            </Link>
                                        </div>
                                    </div>
                                    <div className='content-section-chapter'>
                                        <div className='content'>
                                            {
                                                chapter.filter(value => value.id_section.id_section === items.id_section).map((item, index) => (
                                                    <Fragment key={`unique-${item.id_chapter}`}>
                                                        <div className='model-chapter'>
                                                            <h5 className='title'>{item.chapter_name && item.chapter_name}</h5>
                                                            <div className='media'>
                                                                {
                                                                    imageExtensions.includes(getFileExtension((item.media && item.media.length !== 0) && item.media[0].media_file)) && (
                                                                        <>
                                                                            <img src={`${baseURLMedia}${item.media[0].media_file}`} alt='images' />
                                                                        </>
                                                                    )
                                                                }
                                                                {
                                                                    videoExtensions.includes(getFileExtension((item.media && item.media.length !== 0) && item.media[0].media_file)) && (
                                                                        <>
                                                                            <video width="640" height="360" controls>
                                                                                <source src={`${baseURLMedia}${item.media[0].media_file}`} />
                                                                                Votre navigateur ne supporte pas la balise vidéo.
                                                                            </video>
                                                                        </>
                                                                    )
                                                                }
                                                            </div>
                                                            <div className='btn-edit-delete'>
                                                                <Link to={`/admin/trainings/${id_training}/section/${items.id_section}/chapter/${item.id_chapter}/edit`} className='td-link edit'>
                                                                    <MdEdit className='icon' />
                                                                </Link>
                                                                <Link to='#' className='td-link delete' onClick={() => deleteDataChapter(item.id_chapter, item.chapter_name)}>
                                                                    <MdDelete className='icon' />
                                                                </Link>
                                                            </div>
                                                        </div>
                                                    </Fragment>
                                                ))
                                            }
                                        </div>
                                        <div className='custom-btn-header custom-end'>
                                            <Link to={`/admin/trainings/${id_training}/section/${items.id_section}/quiz`} className='link-btn-custom'>
                                                <KCustomButtonSimaple1 loading={false} type={`button`} texte={`Quiz`} />
                                            </Link>
                                            <Link to={`/admin/trainings/${id_training}/section/${items.id_section}/chapter/add`} className='link-btn-custom'>
                                                <KCustomButtonSimaple1 loading={false} type={`button`} texte={`Add a new chapter`} />
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </Fragment>
                        ))
                    }
                </div>
            </section>

            {/* Add section */}
            <ModelTemplapleSimple icon={true} show={showModalAddSection} hide={() => { setShowModalAddSection(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <h5 className='header'>Add section</h5>
                            <form className='custom-forms' onSubmit={(e) => createSections(e)}>
                                <div className="item">
                                    <label htmlFor="chapter_name" className="form-label"> Name </label>
                                    <input type="text" className={`form-control`} onChange={handleOnChangeAddSection} name='sections_name' />
                                    <small className={`${addSection.sections_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                                <KCustomButtonDouble
                                    loading={loading}
                                    textel={`Non`}
                                    texter={`Oui`}
                                    clickl={() => { setShowModalAddSection(false) }}
                                    clickr={() => { }}
                                    typel={`button`}
                                    typer={`submit`} />
                            </form>
                        </div>
                    </div>
                </>
            } className={'content'} modalClassName={'modal-form-section-add'} />

            {/* Edit section */}
            <ModelTemplapleSimple icon={true} show={showModalEditSection} hide={() => { setShowModalEditSection(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <h5 className='header'>Update section</h5>
                            <form className='' onSubmit={(e) => updateSections(e)}>
                                <div className="item">
                                    <label htmlFor="chapter_name" className="form-label"> Name </label>
                                    <input type="text" className={`form-control`} value={editSection.sections_name} onChange={handleOnChangeEditSection} name='sections_name' />
                                    <small className={`${editSection.sections_name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                                </div>
                                <KCustomButtonDouble
                                    loading={loading}
                                    textel={`Non`}
                                    texter={`Oui`}
                                    clickl={() => { setShowModalEditSection(false) }}
                                    clickr={() => { }}
                                    typel={`button`}
                                    typer={`submit`} />
                            </form>
                        </div>
                    </div>
                </>
            } className={'content'} modalClassName={'modal-form-section-edit'} />

            {/* Delete section */}
            <ModelTemplapleSimple icon={true} show={showModalSectionDelete} hide={() => { setShowModalSectionDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalSectionDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalSectionDelete(false) }}
                            clickr={() => deleteTraningsSections(showModalSectionDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} />

            {/* Delete chapter */}
            <ModelTemplapleSimple icon={true} show={showModalChapterDelete} hide={() => { setShowModalChapterDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalChapterDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalChapterDelete(false) }}
                            clickr={() => deleteTraningsChapters(showModalChapterDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
