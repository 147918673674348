import React, { useEffect } from 'react';
import { MdDelete, MdEdit } from "react-icons/md";
import { Link } from 'react-router-dom';


export const Homes = () => {


    useEffect(() => {
        document.title = "Home";
    });

    return (
        <>
            <section id='dashboard-dashboard'>
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Dashboards list</h4>
                </div>
                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Dashboard name</th>
                                <th scope="col">Refresh Frequency</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Sales activities report</td>
                                <td>Reel Time</td>
                                <td className="td-action">
                                    <Link to={`#`} className='td-link edit me-2'>
                                        <MdEdit className='icon' />
                                    </Link>
                                    <Link to='' className='td-link delete ms-2'>
                                        <MdDelete className='icon' />
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>Rapport des confirmation des ventes </td>
                                <td>J + 1</td>
                                <td className="td-action">
                                    <Link to={`#`} className='td-link edit me-2'>
                                        <MdEdit className='icon' />
                                    </Link>
                                    <Link to='' className='td-link delete ms-2'>
                                        <MdDelete className='icon' />
                                    </Link>
                                </td>
                            </tr>
                            <tr>
                                <td>Globale perfomances report</td>
                                <td>J + 1</td>
                                <td className="td-action">
                                    <Link to={`#`} className='td-link edit me-2'>
                                        <MdEdit className='icon' />
                                    </Link>
                                    <Link to='' className='td-link delete ms-2'>
                                        <MdDelete className='icon' />
                                    </Link>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
            </section>
        </>
    );
};
