import { toast } from "react-toastify";
import { BASE_URL } from "../BASE_URL";
import { ServiceSessions } from "../ServicesSession";

export class ServicesAuthentication {

    static url_sign_in = 'user-connection/';
    static url_logout = 'logout/';

    static sign_in = async (data) => {
        try {
            const response = await BASE_URL.post(
                this.url_sign_in, data, {
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json',
                    // 'Authorization': `Token ${ServiceSessions.getToken()}`
                }
            });
            return response;
        } catch (error) {
            throw error;
        }
    }

    static logout = async () => {
        await BASE_URL.post(
            this.url_logout, null, {
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json',
                'Authorization': `Token ${ServiceSessions.getToken()}`,
            }
        }).then((res) => {
            window.location.href = '/';
            toast.success(res.data.message);
        }).catch((error) => {
            toast.error(error.response.data.token_status);
        });
    }
}