import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Dashboard } from '../views/Dashboard';
import { Homes } from '../views/Homes';

export const HomesControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Dashboard />} />
                <Route exact path='homes' element={<Homes />} />
            </Routes>
        </>
    );
};
