import React, { Fragment, useEffect, useState } from 'react';
import { IoChevronBackSharp } from 'react-icons/io5';
import { Link, useParams } from 'react-router-dom';
import { ServicesTraningQuizAnswerSections } from '../../../../../../../../services/api/ServicesTrainings';
import { toast } from 'react-toastify';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../../../../../components/Button';
import { MdDelete, MdEdit } from 'react-icons/md';
import { SpinnerData } from '../../../../../../../../components/SpinnerData';
import { ModelTemplapleSimple } from '../../../../../../../../components/CustomModal'; 
import { baseURLMedia } from '../../../../../../../../services/BASE_URL';
import { getFileExtension, imageExtensions, videoExtensions } from '../../../../../../../../components/ExtractDate';

export const QuizSections = () => {

    const [loadData, SetLoadData] = useState(true);
    const [loadDataSectionQuiz, SetLoadDataSectionQuiz] = useState(true);
    const { id_training, id_section } = useParams();
    const [quizSection, SetQuizSection] = useState([]);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });

    useEffect(() => {
        getQuizSections();
    }, []);

    // Get all tranings quiz
    const getQuizSections = async () => {
        await ServicesTraningQuizAnswerSections.get().then((res) => {
            SetQuizSection(res.data.Quiz);
            SetLoadData(false);
            SetLoadDataSectionQuiz(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteTraningsQuiz = async (id) => {
        setLoading(true);
        await ServicesTraningQuizAnswerSections.delete(id).then((res) => {
            setShowModalDelete(false);
            getQuizSections();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    const quizList = quizSection.filter(item => item.id_section && item.id_section.id_section === Number(id_section)); 


    return (
        <>
            <section id="admin-section-quiz">
                <div className='content-header'>
                    <div className='cusom-go-back-page mb-3'>
                        <Link to={`/admin/trainings/${id_training}/section`} className='link-btn-custom'>
                            <button type="button" className="btn-custom-primary-icon">
                                <IoChevronBackSharp className='icon' />
                                <span>Retour</span>
                            </button>
                        </Link>
                        <div className='grid-custom-one-column'>
                            <h4 className='title'>Nombre of questions: ({quizList.length})</h4>
                        </div>
                    </div>
                    <Link to={`/admin/trainings/${id_training}/section/${id_section}/quiz/add`} className='link-btn-custom'>
                        <KCustomButtonSimaple loading={false} type={`button`} texte={`Add a new question`} />
                    </Link>
                </div>

                <div className='conetnt-quiz-list'>
                    <div className='spinner-loading'>
                        {loadData && (
                            <SpinnerData />
                        )}
                        {
                            !loadData && quizList.length === 0 && (
                                <>Aucune information disponible</>
                            )
                        }
                    </div>
                    <div className='content-quiz'>
                        {
                            !loadData && quizList.map((item, index) => (
                                <Fragment key={`unique-${index}`}>
                                    <div className='content'>
                                        <div className='sub-content'>
                                            <div className='hearder-content'>
                                                <span className=''>{item.quiz_question_name && item.quiz_question_name}</span>
                                                <span className=''>Points : {item.quiz_question_points && item.quiz_question_points}</span>
                                            </div>
                                            <div className='body-content'>
                                                <div className='item'>
                                                    {
                                                        imageExtensions.includes(getFileExtension((item.quiz_question_media && item.quiz_question_media.length !== 0) && item.quiz_question_media[0].media_file)) && (
                                                            <>
                                                                <img src={`${baseURLMedia}${item.quiz_question_media[0].media_file}`} alt='images' />
                                                            </>
                                                        )
                                                    }
                                                    {
                                                        videoExtensions.includes(getFileExtension((item.quiz_question_media && item.quiz_question_media.length !== 0) && item.quiz_question_media[0].media_file)) && (
                                                            <>
                                                                <video width="640" height="360" controls>
                                                                    <source src={`${baseURLMedia}${item.quiz_question_media[0].media_file}`} />
                                                                    Votre navigateur ne supporte pas la balise vidéo.
                                                                </video>
                                                            </>
                                                        )
                                                    }
                                                </div>
                                                <div className='item'>
                                                    <span>Optin response : </span>
                                                    <span>{item.quiz_question_type && item.quiz_question_type}</span>
                                                </div>
                                            </div>
                                            <div className='footer-content'>
                                                {
                                                    item.answers && item.answers.map((subItem, index) => (
                                                        <Fragment key={`unique-${index}`}>
                                                            <span className={`${subItem.answer_correct && subItem.answer_correct ? 'correct' : 'error'} answer`}>{subItem.answer_label}</span>
                                                        </Fragment>
                                                    ))
                                                }
                                            </div>
                                        </div>
                                        <div className='item-btn'>
                                            <Link to={`/admin/trainings/${id_training}/section/${id_section}/quiz/${item.id_quiz_section}/edit`} className='td-link edit me-2'>
                                                <MdEdit className='icon' />
                                            </Link>
                                            <Link to='#' className='td-link delete ms-2' onClick={() => deleteData(item.id_quiz_section, item.quiz_question_name)}>
                                                <MdDelete className='icon' />
                                            </Link>
                                        </div>
                                    </div>
                                </Fragment>
                            ))
                        }
                    </div>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteTraningsQuiz(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
