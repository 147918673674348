import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesSettinPrivileges } from '../../../../../services/api/ServicesSettings';
 import { KCustomButtonDouble } from '../../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const EditPrivileges = () => {

    const [loading, setLoading] = useState(false);
    const navigate = useNavigate();
    const [errors, setErrors] = useState([]);

    const { id_privilege } = useParams();

    const [privilegeUpdate, setPrivilegeUpdate] = useState({
        name: "",
        description: "",
    })

    useEffect(() => {
        getPrivilegesById();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleOnChange = (e) => {
        setPrivilegeUpdate({
            ...privilegeUpdate,
            [e.target.name]: e.target.value
        });
    }

    const getPrivilegesById = async () => {
        await ServicesSettinPrivileges.getById(id_privilege).then((res) => {
            const resData = res.data.data;
            setPrivilegeUpdate({
                name: resData.name,
                description: resData.description
            });
        }).catch((err) => {
            return err
        });
    }

    const updatePrivileges = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('id_privilege', id_privilege)
        forms.append('name', privilegeUpdate.name)
        forms.append('description', privilegeUpdate.description)

        await ServicesSettinPrivileges.put(id_privilege, forms).then((res) => {
            navigate('/admin/settings/privilege');
            toast.success("privilege update with successfull")
        }).catch((err) => {
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };

    return (
        <>
            <section className="admin-settings-forms">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/settings/privilege`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Update privilege</h4>
                    </div>
                </div>
                <form className='custom-form' onSubmit={(e) => updatePrivileges(e)}>
                    <div className="mb-3">
                        <label htmlFor="name_industry" className="form-label">Name  </label>
                        <input type="text" onChange={handleOnChange} name='name' value={privilegeUpdate.name} className={`form-control`} id="name_industry" />
                        <small className={`${privilegeUpdate.name === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="name_industry" className="form-label">Description  </label>
                        <input type="text" onChange={handleOnChange} name='description' value={privilegeUpdate.description} className={`form-control`} id="name_industry" />
                        <small className={`${privilegeUpdate.description === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/settings/privilege") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section> 
        </>
    );
};
