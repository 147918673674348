import React, { Fragment, useEffect, useState } from 'react';
import { SpinnerData } from '../../../../components/SpinnerData';
import { Link } from 'react-router-dom';
import { KCustomButtonDouble, KCustomButtonSimaple } from '../../../../components/Button';
import { ModelTemplapleSimple } from '../../../../components/CustomModal'; 
import { toast } from 'react-toastify';
import { ServicesCampaigns } from '../../../../services/api/ServocesCampaigns';
import { MdDelete, MdEdit } from 'react-icons/md';

export const urlCampaign = `/admin/campaign`;

export const Campaigns = () => {


    const [campaign, setCampaign] = useState([]);
    const [loadData, SetLoadData] = useState(true);
    const [loading, setLoading] = useState(false);
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [showModalDeleteData, setShowModalDeleteData] = useState({
        "name": "",
        "id": ""
    });


    useEffect(() => {
        document.title = "Campaign";
    });

    useEffect(() => {
        getCampaigns();
    }, []);

    // Get all campaign
    const getCampaigns = async () => {
        await ServicesCampaigns.get().then((res) => {
            setCampaign(res.data.Campagne);
            SetLoadData(false);
        }).catch((err) => {
            return err
        })
    };

    const deleteCampaign = async (id) => {
        setLoading(true);
        await ServicesCampaigns.delete(id).then((res) => {
            setShowModalDelete(false);
            getCampaigns();
            toast.success(res.message);
        }).catch((err) => {
            toast.warning(err.response.data.detail);
        });
        setLoading(false);
    };

    const deleteData = (kid, kname) => {
        setShowModalDeleteData({
            name: kname,
            id: kid,
        });
        setShowModalDelete(true);
    }

    return (
        <>

            <section id="admin-campaign">
                <div className='grid-custom-one-column'>
                    <h4 className='title'>Campaign list</h4>
                    <div className='custom-btn-header custom-end'>
                        <Link to='/admin/campaign/create' className='link-btn-custom'>
                            <KCustomButtonSimaple loading={false} type={`button`} texte={`Add campaign`} />
                        </Link>
                    </div>
                </div>

                <div className='responsible-table-custom'>
                    <table className="table">
                        <thead>
                            <tr>
                                <th scope="col">Country</th>
                                <th scope="col">Client</th>
                                <th scope="col">Name</th>
                                <th scope="col">Category fs</th>
                                <th scope="col">Icon</th>
                                <th scope="col">Required traning</th>
                                <th scope="col">Commissions</th>
                                <th scope="col">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className={`${campaign.length !== 0 && 'd-none'}`}>
                                <td colSpan={8} className={`custom-center-loading`}>
                                    {loadData && (
                                        <SpinnerData />
                                    )}
                                    {
                                        !loadData && campaign.length === 0 && (
                                            <>Aucune information disponible</>
                                        )
                                    }
                                </td>
                            </tr>
                            {
                                !loadData && campaign.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <tr key={index}>
                                            <td>{item.product && item.product.country && item.product.country.country_name}</td>
                                            <td>{item.product && item.product.client && item.product.client.client_name}</td>
                                            <td>{item.name && item.name}</td>
                                            <td>{item.campaign_type && item.campaign_type}</td>
                                            <td>Icon</td>
                                            <td>{item.product && item.product.training_p && item.product.training_p.training_onBoarding ? "Active" : "Desable"}</td>
                                            <td>{item.product && item.product.product_commission}</td>
                                            <td className="td-action">
                                                <Link to={`/admin/campaign/update/${item.id}`} className='td-link edit me-2'>
                                                     <MdEdit className='icon' />
                                                </Link>
                                                <Link to='' className='td-link delete ms-2' onClick={() => deleteData(item.id, item.client_name)}>
                                                    <MdDelete className='icon' />
                                                </Link>
                                            </td>
                                        </tr>
                                    </Fragment>
                                )
                            }
                        </tbody>
                    </table>
                </div>
            </section>

            <ModelTemplapleSimple icon={true} show={showModalDelete} hide={() => { setShowModalDelete(false) }} children={
                <>
                    <div className='grid-custom-one-column grid-gap-custom-normal'>
                        <div className='content-body'>
                            <p>Souhaitez-vous supprimer <strong>{showModalDeleteData.name}</strong> ?</p>
                        </div>
                        <KCustomButtonDouble
                            loading={loading}
                            textel={`Non`}
                            texter={`Oui`}
                            clickl={() => { setShowModalDelete(false) }}
                            clickr={() => deleteCampaign(showModalDeleteData.id)}
                            typel={`button`}
                            typer={`button`} />
                    </div>
                </>
            } className={'content'} modalClassName={'modal-delete-date-table'} /> 
        </>
    );
};
