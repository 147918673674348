import React from 'react';
import { Route, Routes } from 'react-router-dom'; 
import { AuthControllers } from '../auth/controllers/ControllersAuth';
import { AccountsControllers } from '../admin/accounts/controllers/ControllersAccounts';
import { AreaControllers } from '../admin/area/controllers/ControllersArea';
import { ClientsControllers } from '../admin/clients/controllers/ControllersClients';
import { FootSoldiersControllers } from '../admin/footSoldiers/controllers/ControllersFootSoldiers';
import { HomesControllers } from '../admin/homes/controllers/ControllersHomes';
import { TargetsControllers } from '../admin/targets/controllers/ControllersTargets';
import { ProductsControllers } from '../admin/products/controllers/ControllersProducts';
import { SettingsControllers } from '../admin/settings/controllers/ControllersSettings';
import { UsersControllers } from '../admin/users/controllers/ControllersUsers';
import { PosControllers } from '../admin/pos/controllers/ControllersPos';
import { TraningControllers } from '../admin/traning/controllers/ControllersTraning';
import { SetSideBar } from '../../components/sidebar/SetSideBar';
import { CampaignControllers } from '../admin/campaigns/controllers/ControllersCampaign';
 
export const RoutesAdmin = () => {
    return (
        <>
            <Routes>
                <Route exact element={<SetSideBar />} >
                    <Route exact path='/*' element={<HomesControllers />} />
                    <Route exact path='accounts/*' element={<AccountsControllers />} />
                    <Route exact path='clients/*' element={<ClientsControllers />} />
                    <Route exact path='foot-soldiers/*' element={<FootSoldiersControllers />} />
                    <Route exact path='area/*' element={<AreaControllers />} />
                    <Route exact path='pos/*' element={<PosControllers />} />
                    <Route exact path='products/*' element={<ProductsControllers />} />
                    <Route exact path='settings/*' element={<SettingsControllers />} />
                    <Route exact path='targets/*' element={<TargetsControllers />} />
                    <Route exact path='trainings/*' element={<TraningControllers />} />
                    <Route exact path='users/*' element={<UsersControllers />} />
                    <Route exact path='campaign/*' element={<CampaignControllers />} />
                </Route>
            </Routes>
        </>
    );
};

export const RoutesAuthentication = () => {
    return (
        <>
            <Routes>
                <Route exact path='/*' element={<AuthControllers />} />
            </Routes>
        </>
    );
};
