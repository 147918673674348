import React, { Fragment, useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { ServicesClients } from '../../../../services/api/ServicesClients';
import { ServicesSettingsCounties, ServicesSettingsIndustries } from '../../../../services/api/ServicesSettings';
import { KCustomButtonDouble } from '../../../../components/Button';
import { IoChevronBackSharp } from 'react-icons/io5';

export const CreateClient = () => {

    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [errors, setErrors] = useState([]);
    const [industries, setIndustries] = useState([]);
    const [countries, setCountries] = useState([]);
    const [addClient, setAddClient] = useState({
        client_name: "",
        client_logo: "",
        client_industry: "",
        country_id: "",
    })

    useEffect(() => {
        document.title = "Client/add";
    });


    const handleOnChange = (e) => {
        setAddClient({
            ...addClient,
            [e.target.name]: e.target.value
        });
    }

    const handleFileOnChange = (e) => {
        setAddClient({
            ...addClient,
            [e.target.name]: e.target.files[0]
        });
    }

    useEffect(() => {
        getCountries();
        getIndustries_();
    }, []);

    // Get all countries 
    const getCountries = async () => {
        await ServicesSettingsCounties.get().then((res) => {
            setCountries(res.data.Country);
        }).catch((err) => {
            return err
        })
    };

    // Get all industries
    const getIndustries_ = async () => {
        await ServicesSettingsIndustries.get().then((res) => {
            setIndustries(res.data.Industry);
        }).catch((err) => {
            return err
        })
    };

    const createClients = async (e) => {
        e.preventDefault();

        setLoading(true);

        const forms = new FormData();
        forms.append('client_name', addClient.client_name)
        forms.append('client_logo', addClient.client_logo)
        forms.append('client_industry', addClient.client_industry)
        forms.append('country_id', addClient.country_id)

        await ServicesClients.post(forms).then((res) => {
            navigate('/admin/clients');
            toast.success(res.data.message)
        }).catch((err) => {
            setErrors(err.response.data.errors);
            toast.error(err.response.data.message);
        });

        setLoading(false);
    };


    return (
        <>
            <section id="admin-client-create">
                <div className='cusom-go-back-page'>
                    <Link to={`/admin/clients`} className='link-btn-custom'>
                        <button type="button" className="btn-custom-primary-icon">
                            <IoChevronBackSharp className='icon' />
                            <span>Retour</span>
                        </button>
                    </Link>
                    <div className='grid-custom-one-column'>
                        <h4 className='title'>Add  client</h4>
                    </div>
                </div>
                <form onSubmit={(e) => createClients(e)} className='custom-form'>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Client name </label>
                        <input onChange={handleOnChange} name='client_name' type="text" className="form-control" placeholder="" />
                        <small className={`${errors.client_name && (errors.client_name[0] || addClient.client_name === '') ? "errors-fields" : "d-none"}`}>{errors.client_name}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Country</label>
                        <select className={`form-select`} onChange={handleOnChange} name='country_id' >
                            <option>Selected country</option>
                            {
                                countries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_country}>{item.country_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.country_id && (addClient.country_id === '' || addClient.country_id === "Selected country") ? "errors-fields" : "d-none"}`}>{errors.country_id}</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="" className="form-label"> Client logo </label>
                        <input onChange={handleFileOnChange} name='client_logo' type="file" className="form-control" placeholder="" />
                        <small className={`${addClient.client_logo === '' ? "errors-fields" : "d-none"}`}>This field may not be blank.</small>
                    </div>
                    <div className="mb-3">
                        <label htmlFor="formFile" className="form-label">Industries</label>
                        <select className={`form-select`} onChange={handleOnChange} name='client_industry'>
                            <option>Selected industries</option>
                            {
                                industries.map((item, index) =>
                                    <Fragment key={`unique-${index}`}>
                                        <option value={item.id_industry}>{item.industry_name}</option>
                                    </Fragment>
                                )
                            }
                        </select>
                        <small className={`${errors.client_industry && (addClient.client_industry === '' || addClient.client_industry === "Selected industries") ? "errors-fields" : "d-none"}`}>{errors.client_industry}</small>
                    </div>
                    <br />
                    <KCustomButtonDouble
                        loading={loading}
                        textel={`Annuler`}
                        texter={`Valider`}
                        clickl={() => { navigate("/admin/clients") }}
                        clickr={() => { }}
                        typel={`button`}
                        typer={`submit`} />
                </form>
            </section>
        </>
    );
};