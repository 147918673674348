import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { Pos } from '../views/Pos'; 
import { CreatePos } from '../views/Create';


export const PosControllers = () => {
    return (
        <>
            <Routes>
                <Route exact index element={<Pos />} />
                <Route exact path='add' element={<CreatePos />} />
            </Routes>
        </>
    );
};
